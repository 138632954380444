import {useState,useEffect} from 'react';
import styles from './InterviewTypes.module.css'

import { FormHelperText, TextField, Typography } from '@mui/material';
import jobList from "./../../assets/jobList";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import skillList from 'assets/skillsList.js' 

const InterviewRole = ({profile,setrequestInfo,requestInfo,userData}) => {

  const filter = createFilterOptions();

  let interviewList = profile.interviewTypes.map((item)=>{
    return {title:item}
  })



      
    return (
        <div className={styles.container} style={{width:"50vw"}} className="modal-modal">

          
<Autocomplete
          sx={{ ml: 1, mt: 2 }}
          required
          value={requestInfo.role}
          onInputChange={(event, newValue) => {
            if(!newValue)  return
            setrequestInfo({ ...requestInfo, role: newValue })
     
           }}
           
          onChange={(event, newValue) => {
            if(!newValue)  return
            setrequestInfo({
              ...requestInfo,
              role: newValue.inputValue || newValue.title,
            });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="target-role"
          options={jobList}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField required {...params} label="What is your target role" />
          )}
        />
<Autocomplete
          sx={{ ml: 1, mt: 2 }}
          required
          value={requestInfo.interviewType}
          onInputChange={(event, newValue) => {
            setrequestInfo({ ...requestInfo, interviewType: newValue })
     
           }}
           
          onChange={(event, newValue) => {
            if(!newValue)  return
            setrequestInfo({
              ...requestInfo,
              interviewType: newValue.inputValue || newValue.title,
            });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          id="target-role"
          options={interviewList}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          renderInput={(params) => (
            <TextField required {...params} label="Choose interview type" />
          )}
        />
   
   { requestInfo.interviewType === 'Technical Interview' &&(
      <>
  
              <Autocomplete
              clearOnBlur={false}
         sx={{ m: 1,mb:1,mt:2 }}
        id="free-solo-demo"
        freeSolo
        value={requestInfo.topic}
        onChange={(e,value) => setrequestInfo({ ...requestInfo, topic: value })}
        options={skillList}
        renderInput={(params) => <TextField {...params} label="Choose a topic for the interview (optional)" placeholder='frontend, backend, react ...'/>}
      />
        
            <FormHelperText sx={{ml:1}}>
            If you chose a topic the whole interview will be about that specif skill, and the questions generated will be about it, else it will be general technical mock interview
                  </FormHelperText>
      </>
     )}

        </div>
    );
}

export default InterviewRole;
