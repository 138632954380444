import React,{useEffect} from "react";
import {
  Button,
  Container,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Box,
  FormControl,
  Autocomplete
} from "@mui/material";

const Education = ({ user, setUser, handleNext, handleSubmit, children }) => {
  const levels = [
    "High school diploma",
    // "Some College",
    "Bootcamp Certificate",
    "Associate degree",
    "Bachelor degree",
    "Master degree",
    "Doctoral degree",
  ];

  const programs = [
    "IT/Desktop Support",
    "Software/Web Development",  
    "Data Science",
    "UX/UI Design",
    "Cyber Security",
    "Data Analytics",
    "Product Management",
    "Digital Marketing",
    "AWS",
    "Database Administration",
    "Other "

  ]
  const areasOfStudy = [
    'Computer Science',
    'Information Technology',
    'Data Science',
    'Web Development',
    "Psychology",
    "MBA",
    'Software Engineering',
    'Cybersecurity',
    'mechanical engineering',
    'Machine Learning',
    'Network Engineering',
    'Database Management',
  ];
  
  
  return (
    <>
      
         <Box sx={{ display: "flex", flexDirection: "column" }}>

      <Typography variant="h3" gutterBottom sx={{textAlign:"center"}}>
        What is Your Education
      </Typography>

         <FormControl sx={{mt:2}}>
      <InputLabel required>Degree Level</InputLabel>
      <Select
        onChange={(e) =>{ 
          setUser({ ...user, educationLevel: e.target.value })
        console.log(user)
      if(e.target.value === "High school diploma" ||e.target.value === "Some College"){
        handleNext()
      }
      }}
        required
        value={user.educationLevel}
        label="Degree Level"
      >
        {levels.map((level) => (
          <MenuItem key={level} value={level} required>
            {level}
          </MenuItem>
        ))}
      </Select>
      </FormControl>

      {user.educationLevel !== "High school diploma" &&
        user.educationLevel !== "Some College" &&
        user.educationLevel !== ""  && (
         user.educationLevel === "Bootcamp Certificate"?
         <>
           <FormControl sx={{ mt: 2}}>

<InputLabel required>Choose program</InputLabel>
 <Select
        onChange={(e) => setUser({ ...user, areaOfStudy: e.target.value })}
        required
        value={user.areaOfStudy}
        label="Choose program"
      >
        {programs.map((level) => (
          <MenuItem key={level} value={level} required>
            {level}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <TextField
              sx={{ ml: 0, mt: 2 }}
              id="bootcamp"
              
              value={user.university}
              placeholder="General Assembly"
              label="What Bootcamp did you graduate from"
              onChange={(e) => setUser({ ...user, university: e.target.value })}
            />
         </> :<>
         <Autocomplete
  sx={{ ml: 0, mt: 2 }}
  id="areaOfStudy"
  options={areasOfStudy}
  value={user.areaOfStudy}
  freeSolo
  onChange={(event, newValue) => {
    setUser({ ...user, areaOfStudy: newValue });
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label="What is your area of study"
      placeholder="Computer Science"
    />
  )}
/>
            <TextField
              sx={{ ml: 0, mt: 2 }}
              id="university"
              
              value={user.university}
              placeholder="Harvard"
              label="What university did you graduate from"
              onChange={(e) => setUser({ ...user, university: e.target.value })}
            />
          </>
        )}
         </Box>

    </>
  );
};

export default Education;
