import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";

//input list
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import FormLabel from "@mui/material/FormLabel";
//input
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import companyList from "assets/companyList"

import jobList from "assets/jobList"
import { Slider } from "@mui/material";


const ModalForUserInfo = ({ user, setUser }) => {
  console.log("🚀 ~ ModalForUserInfo ~ user:", user)
  let list = companyList.map((item)=>{
    return {title:item}
  })

  const filter = createFilterOptions();
  const levels = [
    "High school diploma",
    // "Some College",
    "Bootcamp Certificate",
    "Associate degree",
    "Bachelor degree",
    "Master degree",
    "Doctoral degree",
  ];

  const programs = [
    "IT/Desktop Support",
    "Software/Web Development",  
    "Data Science",
    "UX/UI Design",
    "Cyber Security",
    "Data Analytics",
    "Product Management",
    "Digital Marketing",
    "AWS",
    "Database Administration",
    "Other "

  ]

  function valuetext(value) {
    return `${value}`;
}

const marks = [
    { value: 0, label: '0' },
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
];

   
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControl required sx={{ ml: 1 }}>
        <InputLabel>choose your highest education level</InputLabel>
        <Select
          onChange={(e) => setUser({ ...user, educationLevel: e.target.value })}
          required
          value={user.educationLevel}
          label="choose your highest education level"
          defaultValue=" "
        >
          {levels.map((level) => (
            <MenuItem key={level} value={level}>
              {level}
            </MenuItem>
          ))}
        </Select>
        {user.educationLevel !== "High school diploma" &&
        user.educationLevel !== "Some College" &&
        user.educationLevel !== ""  && (
         user.educationLevel === "Bootcamp Certificate"?
         <>
           <FormControl sx={{ mt: 2}}>

<InputLabel required>Choose program</InputLabel>
 <Select
        onChange={(e) => setUser({ ...user, areaOfStudy: e.target.value })}
        required
        value={user.areaOfStudy}
        label="Choose program"
      >
        {programs.map((level) => (
          <MenuItem key={level} value={level} required>
            {level}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <TextField
              sx={{ ml: 0, mt: 2 }}
              id="bootcamp"
              required
              value={user.university}
              placeholder="General Assembly"
              label="What Bootcamp did you graduate from"
              onChange={(e) => setUser({ ...user, university: e.target.value })}
            />
         </> :<>
            <TextField
              sx={{ ml: 0, mt: 2 }}
              id={"areaOfStudy"}
              required
              placeholder="Computer Science"
              value={user.areaOfStudy}
              label="What is your area of study"
              onChange={(e) =>
                setUser({ ...user, areaOfStudy: e.target.value })
              }
            />
            <TextField
              sx={{ ml: 0, mt: 2 }}
              id="university"
              required
              value={user.university}
              placeholder="Harvard"
              label="What university did you graduate from"
              onChange={(e) => setUser({ ...user, university: e.target.value })}
            />
          </>
        )}

     
      </FormControl>


              
   <Autocomplete
        sx={{ ml: 1, mt: 2 }}
        required
      value={user.isStudent?"student":user.currentPosition}
      onInputChange={(event, newValue) => {
        setUser({ ...user, currentPosition: newValue })
 
       }}
       
      onChange={(event, newValue) => {
        if(!newValue)  return
       setUser({ ...user, currentPosition: newValue.inputValue || newValue.title })
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        // console.log("🚀isExisting:", isExisting)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      freeSolo
      handleHomeEndKeys
      id="target-role"
      options={jobList}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
    
      
      renderInput={(params) => (
        <TextField  {...params} label="Current/Last position"  helperText="if you are student put student"/>
        
      )}
      

    />

   {user.currentPosition .toLowerCase()!=="student" && <>
   
   <Autocomplete
       sx={{ ml: 1, mt: 2,mb:1 }}
      value={user.currentCompany}
      onInputChange={(event, newValue) => {
        setUser({ ...user, currentCompany: newValue })
 
       }}
       
      onChange={(event, newValue) => {
        if(!newValue)  return
        setUser({...user,currentCompany: newValue.inputValue || newValue.title })
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        console.log("🚀=filtered:", filtered)

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        // console.log("🚀isExisting:", isExisting)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      freeSolo
      handleHomeEndKeys
      id="target-role"
      options={list}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        return option.title;
      }}
      renderOption={(props, option) => <li {...props}>{option.title}</li>}
    
      
      renderInput={(params) => (
        <TextField  {...params} label="Add the last company your worked for" helperText="leave it empty if you don't have a job/student"/>
      )}
      

    />
    
        <InputLabel  id="demo-simple-select-autowidth-label" sx={{ ml:1 , minWidth: 300 }}>
          Years of Experience
          </InputLabel>
        <FormControl sx={{ ml: 1, minWidth: 300 }}>
          <Box sx={{ width: '99%' }}>
                <Slider
                    aria-label="Years of Experience"
                    defaultValue={user.yoe||0}
                    value={user.yoe}
                    getAriaValueText={valuetext}
                    step={1}
                    marks={marks}
                    min={0}
                    max={20}
                    valueLabelDisplay="auto"
                    onChange={(e,n)=>setUser({ ...user,yoe:n})}
                />
            </Box>
        </FormControl>
                    </>}
    </Box>
  );
};

export default ModalForUserInfo;
