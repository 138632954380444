import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Work";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Notification from "./notifications/Notification";
//hooks
import { useLogout } from "hooks/useLogout";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { useAuthContext } from "hooks/useAuthContext";
import Drawer from "./DrawerBar";
import { useTheme } from "@mui/system";
import peer1 from "/logo.webp"
const Nav = ({ shownav, setshownav,notificationDoc }) => {
  const { user } = useAuthContext();


  const [Modal, setModal] = useState(false);
  const navigate = useNavigate();
  let pages = [
    "Questions",
    "Cover Letter genrator",
    // 'CodePractice'
  ];
  if (user) {
    pages = [
      "Questions",
      "Interviews",
      "Cover Letter genrator",
      // 'CodePractice'
    ];
  }
  const settings = ["Profile","Reviews","Settings"];
  const [anchorElUser, setAnchorElUser] = useState(null);

  const { logout } = useLogout();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    // console.log('clicked')
    //  switch(setting){
    //   case 'LOGIN':
    //     return console.log('what')
    // case 'Logout':
    //     return console.log('logout')
    // default:
    //     return console.log('what did you click')
    //  }
  };

  const location = useLocation();
  const checkIfuserIsIn = () => {
    if (
      location.pathname === "/login" ||
      location.pathname === "/signup" ||
      location.pathname.substring(0, 5) === "/meet" ||location.pathname.includes('practice')
      ||location.pathname.includes('problemsPractice')
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);
    switch (setting) {
      case "Logout":
        return logout();
      case "Reviews":
        return navigate("/Reviews");
      case "Profile":
        return navigate("./profile/"+user.uid);
      case "Settings":
        return navigate("./settings");
      case "codeWars":
        return navigate("./codewars");
      case "interviews":
        return navigate("./interviews");
      
      default:
        return null;
    }
  };


  useEffect(() => {
    setshownav(checkIfuserIsIn);
  }, [location]);



  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = user ? 240 : 0;


  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleCloseNavMenu = (e, setting) => {
    setAnchorElNav(null);
    console.log('clicked',setting)
    switch (setting) {
      case "Questions":
        return navigate("/questions");
      case "Cover Letter genrator":
        return navigate("/coverletter");
      case "CodePractice":
        return navigate("/practice");
      default:
        return null;
    }
  };
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  return (
    <>
      {shownav && (
        <Box sx={{ display: "flex", height: 65 }}>
          <AppBar
            position="sticky"
            sx={{
              bgcolor: "#4960A7",
              boxShadow: 0,
              width: { md: `calc(100% - ${drawerWidth}px)` },
              ml: { md: `${drawerWidth}px` },
            }}
          >
            <Container maxWidth="xl" sx={{ml:0} }>
              <Toolbar disableGutters>
                <Toolbar className="hi" sx={{p:'0!important'}} >
                  {user ? (
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { md: "none" } }}
                    >
                      <MenuIcon />
                    </IconButton>
                  ) : (
                    <>
                    <Box className="hi" sx={{ display: { xs: "none", md: "flex" },mr:-20}}
 >
                      <Link to="/coverletter" >
                        <Button
                          color="inherit"
                          sx={{ color: "white", width: 160 }}
                          >
                          Cover Letter
                        </Button>
                      </Link>
                      <Link to={user?"/questions":"/login"}>
                        <Button
                          color="inherit"
                          sx={{ color: "white", width: 160 }}
                          >
                          question Library
                        </Button>
                      </Link>
                    </Box>
                    <Box
                    sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                  >
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                        display: { xs: "block", md: "none" },
                      }}
                    >
                      {pages.map((page) => (
                        <MenuItem
                          key={page}
                          onClick={(e) => handleCloseNavMenu(e, page)}
                        >
                          <Typography textAlign="center">{page}</Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                          </>
                  )}
                 
                </Toolbar>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center",margin:'auto',height:60,width:203 }} >
                    <Link to="/">
                    <img src={peer1} alt="logo" style={{height:60,width:203}}/>
                    </Link>
                  </div>
                </div>
                {user && (
                  <>
                    <Notification document={notificationDoc}/>
                    <Box sx={{ flexGrow: 0, ml: 2, mr: 2 }}>
                      <Tooltip title="Open profile options">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            src={user.photoURL}
                          sx={{ bgcolor: deepOrange[500] }}
                          >
                            {user?.displayName&&user.displayName[0].toUpperCase()}
                            {/* {user.displayName.charAt(0).toUpperCase()} */}
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItem
                            key={setting}
                            onClick={() => handleCloseUserMenu(setting)}
                          >
                            <Typography textAlign="center">
                              {setting}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </>
                )}
                {!user && (
                  <>
                    <Link to="/login" style={{textDecoration:'none'}}>
                      <Button color="inherit" sx={{ color: "white" }}>
                        Login
                      </Button>
                    </Link>
                    <Link to="/signup" style={{textDecoration:'none'}}>
                      <Button color="inherit" sx={{ color: "white" }}>
                        signup
                      </Button>
                    </Link>
                  </>
                )}
                {/* <Button color="inherit" onClick={logout}>Logout</Button> */}
              </Toolbar>
            </Container>
           
    
          </AppBar>
          {user && (
            <Drawer
            user={user}
              setModal={setModal}
              handleDrawerToggle={handleDrawerToggle}
              setMobileOpen={setMobileOpen}
              mobileOpen={mobileOpen}
              pages={pages}
            />
          )}
        </Box>
      )}
    </>
  );
};
export default Nav;
