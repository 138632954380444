import React, { useState, useEffect } from "react";
import styles from "../content.module.css";
import Categories from "./Filters";
import jobListInitialData from "assets/jobList";
import companyList from "assets/companyList";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import useAnalytics from "src/assets/useAnalytics"

export default function FiltersList({
  setFilters,
  filter,
  setFilter,
  matchUsers,
  usersList,
  rolesList,
}) {
  let list = jobListInitialData.map((item) => item.title);
  const {logEvent,identify} = useAnalytics()

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const categories = [
    { name: "Role", menu: list.filter((com) => com.length < 18) },
    { name: "Company", menu: companyList.filter((com) => com.length < 18) },
    { name: "Experience level", menu: ["Entry Level", "Mid Level", "Senior Level"] },
    { name: "Availability", menu: days },
  ];

  const [filterArray, setFilterArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showClearButton, setShowClearButton] = useState(false);
  // Debounce effect for setFilter
  useEffect(() => {
    const timer = setTimeout(() => {
      // Only set filter if searchQuery has changed
      if(!searchQuery &&showClearButton){
setShowClearButton(false)
      }
      if (filter.search !== searchQuery) {
        logEvent("search-filter",{searchQuery})
        setFilter({ ...filter, search: searchQuery });
        matchUsers(true)
      }
    }, 500); // Adjust the delay to your preference

    // Clean up the timer when searchQuery changes or component unmounts
    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery, setFilter, filter]);

  // Update searchQuery on input change
  const searchOnChange = (e) => {
    setSearchQuery(e.target.value);
    if(e.target.value?.length>0){
      setShowClearButton(true)
  }else{
    if(showClearButton){
      setShowClearButton(false)
    }
  }
  }
  return (
    <div className={styles.filterContainer}>
      {categories.map((category) => (
        <div className={styles.filter} key={category.name}>

        <Categories
        matchUsers={matchUsers}
        category={category.name}
        menu={category.menu}
        setFilter={setFilter}
        filter={filter}
        setFilterArray={setFilterArray}
        filterArray={filterArray}
        />
        </div>
      ))}
      <TextField
      className={styles.searchField}
        onChange={searchOnChange}
        id="outlined-basic"
        label="Search"
        variant="outlined"
        value={searchQuery}
        sx={{
          input: {
            background: "white",
            // width: "100px",
            
          },
          fieldset: { borderColor: "#e5e6e6" },
        }}
        InputProps={{
          style: {
            padding: "0px",
            borderRadius: "10px",
            height: "64px",
          },
          endAdornment: (
            <>
        { showClearButton&&   <ClearIcon onClick={() =>{
           setSearchQuery("")
           setShowClearButton(false)
           matchUsers(true,true)
           }} sx={{cursor:"pointer"}}>
             {/* {true ? <ClearIcon/> : ''} */}
            </ClearIcon>
        }
            </>
          )


        }}
      />
    </div>
  );
}
