import { React, useState, useEffect } from "react";
import { useLogin } from "hooks/useLogin";
import { UseSignByGoogle } from "hooks/UseSignByGoogle";

import "./Login.css";
import interview from "/Signinterview.webp";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";

export default function Login({ user,setNewUser }) {
  const location = useLocation();
  const path = location.pathname;
  const { signByGoogle } = UseSignByGoogle();
  const { error, login, loading, success } = useLogin();
  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  // const handleSubmit=(e)=>{
  //     e.preventDefault()
  //     // console.log(displayName)
  //     login(Email,Password)
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    login(values.email, values.password, path);
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  return (
    <div className="log">
      <div className="login-container">
        <div className="image">
          <img src={interview} alt="interview illustration" />
        </div>
        <div className="login-form">
          <p className="form-title">Welcome back</p>
          <p>Login to start Interviewing</p>
          <form onSubmit={handleSubmit}>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              required
              onChange={handleChange("email")}
              id="Email"
              value={values.email}
              label="Email"
              // defaultValue="Email"
            />


            
            <FormControl sx={{ mb: 2 }} fullWidth required variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                required
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            {error && (
              <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                <Alert variant="outlined" severity="error">
                  {error
                    .split("/")
                    .slice(1)
                    .join(" ")
                    .split(")")
                    .slice(0, 1)
                    .join(" ")}
                </Alert>
              </Stack>
            )}

            {/* <Button fullWidth variant="contained" type='submit'>Login</Button> */}
            <Box sx={{ mt: 0, position: "relative" }}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={buttonSx}
                disabled={loading}
                onClick={handleSubmit}
              >
                Login
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </form>
          <Divider>
 
            <Typography variant="overline" display="block" gutterBottom>
              or
            </Typography>
          </Divider>
          <button
            type="button"
            className="login-with-google-btn"
            onClick={() => signByGoogle(path,setNewUser)}
          >
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4="
              alt=""
            />
            Sign in with Google
          </button>
          <Typography
            sx={{ mt: 2 }}
            variant="caption"
            display="block"
            gutterBottom
          >
            New user? <Link to="/signup">Signup</Link>
          </Typography>
          {/* <Typography sx={{mt:2}} variant="caption" display="block" gutterBottom>
          <Link to="/signup">Forgot password</Link>
      </Typography> */}
        </div>
      </div>
    </div>
  );
}
