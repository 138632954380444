import React from "react";
// import Banner from './pictures/bannera.png'
import BannerImage from "/banner.webp";
// import interview from ".//interview.jpg"
import { Link } from "react-router-dom";
import styles from "./Banner.module.css";
import useAnalytics from "src/assets/useAnalytics"

const Banner = () => {
  const {logEvent,identify} = useAnalytics()

  return (
    <div>
      <div className={styles.banner}>
        <div className={styles.text}>
          <h1>Free Mock Interview Practice With Real People</h1>
          <p>
            Empower Your Interview Skills with free mock interview Practice with real people, Expert Feedback, and a Library of Interview Questions 
 
          </p>
          {/* <p>
            fully anonymous 
          </p>
          <p>unlimited mock interviews</p> */}
          {/* maybe have this one on top instead, either scare tactic or second ace your interview before having it  */}
          <Link
            style={{ textDecoration: "none" }}
            to="/signup"
            onClick={() => {
              logEvent("banner Button Click");
            }}
          >
            <button className={styles.smallButton}>Sign Up </button>
            <button className={styles.bigButton}>
              Sign Up Now and Get Ahead of the Competition
            </button>
          </Link>
        </div>

        <img src={BannerImage} alt="banner" />
        {/* <img src={interview} alt="interview illustration" /> */}
      </div>
    </div>
  );
};

export default Banner;
