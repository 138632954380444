import React from 'react';
import {useDocumentList} from 'hooks/useDocumentList'
import { useState, useEffect, useRef } from "react";
import { useAuthContext } from 'hooks/useAuthContext';
import PastInterview from './PastInterview';
import Skeleton from "@mui/material/Skeleton";

const PastInterviews = () => {
  const {user}=  useAuthContext()
    const {getDocuments } =useDocumentList() 
const [interviews,setInterviews] = useState(null)

    const get= async ()=>{
        let queries = [['userIds','array-contains',user.uid]]
        const docs =await getDocuments('previousInterviews',queries)

        setInterviews(docs)
    }
    useEffect(() => {
     get()
    }, [])
    

    return (
        <div>
            {interviews ? <div>

            {interviews.map((item)=>(
           
                <PastInterview interview={item} user={user} key={item.id}/>
            
            ))}
            </div>:<div>
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            <Skeleton sx={{ height: 200 }} />
            </div>}
            
        </div>
    );
}

export default PastInterviews;
