import React from "react";
import { useState, useEffect } from "react";

//select
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

import Stack from "@mui/material/Stack";
import companyList from "assets/companyList";

import jobList from "assets/jobList";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { FormControlLabel, FormLabel, Slider, Switch } from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";

const ModalUserGoals = ({ user, setUser }) => {
  //maybe move this to be second page


  const filter = createFilterOptions();

  const interviewtypesList = [
    {
      type: "General Interview",
      description: `practice normal interview that covers a range of aspects relevant to the role with topics such as your professional background, skills, accomplishments, problem-solving abilities.`,
    },
    {
      type: "Behavioral Interview",
      description:
        "Practice behavioral interview questions, covering topics such as describing your recent professional experience, workplace challenges, teamwork skills, and more!",
    },
    {
      type: "Technical Interview",
      description:
        "practice technical interview aimed at evaluating your expertise and problem-solving skills relevant to the role. This interview will delve into your practical knowledge of tools, technologies, and methodologies essential for the position.",
    },
    {
      type: "coding Interview",
      description:
        "Practice Coding problems and leetcode questions. This interview will test your problem solving skills and language features",
    },
    {
      type: "System Design",
      description:
        "Practice system design questions covering topics such as API design, scaling, performance, data modeling, network architecture and more",
    },

    {
      type: "Case Study Interview",
      description:
        "Prepare for a case study interview where you'll be presented with real or hypothetical business scenarios. Your task is to analyze the situation, identify key issues, and propose well-reasoned solutions. This interview assesses your problem-solving, analytical thinking, and communication skills as you walk through your approach to tackling complex challenges.",
    },
  ];
  const handleDeleteInterview = (index) => {
    let arr = user.interviewTypes;
    arr.splice(index, 1);
    setUser({ ...user, interviewTypes: arr });
  };
  let topics = [
    "Microsoft Office",
    "CompTIA A+",
    "CompTIA Security+",
    "googleCloud",
    "Angular",
    "Azure",
    "AmazonWebServices",
    "Vue",
    "jQuery",
    "WebSockets",
    "React",
    "React Native",
    "Docker",
    "NodeJs",
    "ExpressJs",
    "Mongodb",
    "Next",
    "Nuxt",
    "Redux",
    "Spring",
    "Hibernate",
    "GitHub",
    "Struts",
    "Google web toolkit",
    "JavaServer Faces",
    "Grails",
    "Vaadin",
    "Blade",
    "Play",
    "Ember",
    "Meteor",
    "Polymer",
    "Aurelia",
    "Backbone",
    "C",
    "Python",
    "Java",
    "C++",
    "C#",
    "JavaScript",
    "PHP",
    "SQL",
    "Groovy",
    "Classic Visual Basic",
    "Fortran",
    "R",
    "Ruby",
    "Swift",
    "Matlab",
    "Go",
    "Perl",
    "SASS",
    "pascal",
    "Delphi",
    "Objective-C",
    "Rust",
    "Julia",
    "Ada",
    "Lisp",
    "Dart",
    "Scala",
    "COBOL",
    "ABAP",
    "Kotlin",
    "Lua",
    "TypeScript",
    "HTML",
    "CSS",
    "Visual Basic .NET",
    "Assembly language",
    "F#",
    "Scheme",
    "Prolog",
    "Bash",
  ];

  topics = topics.map((item) => {
    return { title: item };
  });

  const handleDeleteTopic = (index) => {
    console.info("You clicked the delete icon.");
    let arr = user.topics;
    arr.splice(index, 1);
    setUser({ ...user, topics: arr });
  };
  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Autocomplete
          sx={{ ml: 1, mt: 2 }}
          required
          onChange={(event, newValue) => {
            if(!newValue)  return
            let arr = user.topics || [];
            if (arr.includes(newValue.inputValue || newValue.title)) return;
            arr.push(newValue.inputValue || newValue.title);
            setUser({ ...user, topics: arr });
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          freeSolo
          handleHomeEndKeys
          id="target-role"
          options={topics}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          renderInput={(params) => <TextField {...params} label="Topics" />}
        />

        <div style={{ marginLeft: 10 }}>
          <FormHelperText>
            Select the topics You want the interview to be about
          </FormHelperText>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: 8,
            }}
          >
            {user.topics?.map((item, index) => (
              <Chip
                key={item}
                label={item}
                variant="outlined"
                onDelete={() => handleDeleteTopic(index)}
              />
            ))}
          </div>
        </div>

        <Stack sx={{ ml: 1,mt:2 }}>
          <Autocomplete
            sx={{ mt: 1 }}
            required
            onChange={(event, newValue) => {
              if(!newValue)  return
              let arr = user.interviewTypes || [];
              if (arr.includes(newValue.inputValue || newValue.type)) return;
              arr.push(newValue.inputValue || newValue.type);
              setUser({ ...user, interviewTypes: arr });
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.type
              );
              // console.log("🚀isExisting:", isExisting)
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  type: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            freeSolo
            handleHomeEndKeys
            id="target-role"
            options={interviewtypesList}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.type;
            }}
            renderOption={(props, option) => <li {...props}>{option.type}</li>}
            renderInput={(params) => (
              <TextField {...params} label="Interview Types" />
            )}
          />

          <div>
            <FormHelperText>
              Add the interview types you are interested in practicing
            </FormHelperText>
            <div
              style={{
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: 8,
                marginTop:10
              }}
            >
              {user.interviewTypes?.map((item, index) => (
                <Chip
                  key={item}
                  label={item}
                  variant="outlined"
                  onDelete={() => handleDeleteInterview(index)}
                />
              ))}
            </div>
          </div>
          {user.interviewTypes.includes("coding Interview") && (
            <div>
              <FormLabel component="legend" sx={{ mt: 1, mb: 1 }}>
                How hard you want the coding problems to be?
              </FormLabel>
              <ToggleButtonGroup
                value={String(user.codingLevel)}
                exclusive
                onChange={(event, newAlignment) => {
                  setUser({
                    ...user,
                    codingDifficulty: Number(newAlignment),
                    codingLevel: String(newAlignment),
                  });
                }}
                aria-label="Platform"
              >
                <ToggleButton value="4" color="primary">
                  Normal
                </ToggleButton>
                <ToggleButton value="6" color="error">
                  Hard
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          )}
          <FormLabel component="legend" sx={{ mt: 1 }}>
            Will you be turning on camera on mock inteviews?
          </FormLabel>

          <FormControlLabel
            control={
              <Switch
                checked={user.camera}
                onChange={() => setUser({ ...user, camera: !user.camera })}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={user.camera ? "Yes" : "No"}
            labelPlacement="end"
          />
        </Stack>
      </Box>
    </div>
  );
};

export default ModalUserGoals;
