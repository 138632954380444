import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InterviewCard from "./InterviewCard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));





export default function InterviewList({ documents, cancelInterview, user,startInterview ,privateData}) {
  const [list, setlist] = useState(null);

  useEffect(() => {
    if(documents && privateData){

      //TODO: delete interviews after their time passed
      let filteredDocuments
      if(!import.meta.env.DEV){
         filteredDocuments = documents.filter((doc)=> (Date.now()-doc.time.timestamp)/1000/60 <=10)
      }else{
        filteredDocuments = documents
      }
    const rows = filteredDocuments.map((doc) => {
      console.log('doc',doc)
      const formatedDate = new Date(doc.time.timestamp).toDateString() 
      const formatedTime = new Date(doc.time.timestamp).toLocaleTimeString("en-US")
  
      const time = formatedDate.substring(10,0) +' at ' +formatedTime.replace(':00 ',' ')
      const {questions} = doc.users.find((item)=>item.id === user.uid)
      console.log("🚀 ~ file: InterviewCard.js:193 ~ rows ~ fdsf:", questions)
      let name = ''
      let peerId =''
      try{

        const {name:nameD,id} = doc.users.find((item)=>item.id !== user.uid)
 
        name = nameD
        peerId = id
      }catch(err){
        console.log(err)
      }
      const {id:userId,codingProblem} = doc.users.find((item)=>item.id === user.uid)
      console.log("🚀 ~ rows ~ doc.users.find((item)=>item.id === user.uid):", doc)
      let userLink = doc.personaId|| peerId
      if(privateData?.admin){
        userLink = peerId
      }
      let isTime = Date.now() >= doc.time.timestamp
      return {
        ...doc,
        role:doc.role,
        userId,
        peerId,
        roomId: doc.id,
        peerName: name,
        when: time, 
        InterviewType: doc.interviewType,
        interviewDetails: doc.interviewDetails,
        time: doc.time.timestamp,
        details: doc.interviewDetails,
        questions:questions,
        doc,
        codingProblem:codingProblem,
        topic:doc.topic,
        userLink,
        isTime
      };
    });
    setlist(rows);
  }

  }, [documents,privateData]);

  return (
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell sortDirection="desc"></StyledTableCell>
            <StyledTableCell sortDirection="desc" align="center">Peer</StyledTableCell>
            <StyledTableCell sortDirection="desc" align="center">Role</StyledTableCell>
            <StyledTableCell sortDirection="desc" align="center">When</StyledTableCell>
            <StyledTableCell align="center">Interview Type</StyledTableCell>
            <StyledTableCell sortDirection="desc"align="center">
              Question You'll Ask
            </StyledTableCell>
            
            <StyledTableCell align="center">Topic</StyledTableCell>
            <StyledTableCell align="center">action</StyledTableCell>
          </TableRow>
        </TableHead>
        {list && (
          <TableBody>
            {list.map((row, index) => (
              <InterviewCard
                key={index}
                row={row}
                index={index}
                documents={documents}
                cancelInterview={cancelInterview}
                startInterview={startInterview}
                StyledTableCell={StyledTableCell}
              />
            ))}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}
