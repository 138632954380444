import  React,{useState} from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LanguageIcon from "@mui/icons-material/Language";
import GroupsIcon from "@mui/icons-material/Groups";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link,useLocation } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLogout } from "hooks/useLogout";
import TerminalIcon from '@mui/icons-material/Terminal';
import Feedback from "./Feedback";
import GroupIcon from '@mui/icons-material/Group';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import InviteFriendModal from "./inviteFriend/InviteFriendModal";
const drawerWidth = 240;

const DrawerBar = (props) => {
  const { user,window, mobileOpen, handleDrawerToggle, setMobileOpen, setModal } =
    props;
  const pages = [
    { to: "/browse", icon: <LanguageIcon />, name: "Browse" },
    { to: "/interviews", icon: <PictureInPictureIcon />, name: "Interviews" },
    { to: "/questions", icon: <QuestionAnswerIcon />, name: "Questions" },
    {
      to: "/coverletter",
      icon: <TextSnippetIcon />,
      name: "Cover Letter Generator",
    },
    // {
    //   to: "/coverletter",
    //   icon: <GroupIcon />,
    //   name: "practice with a friend",
    // },

  ];
  const settings = [
    { to: "/profile/"+user.uid, icon: <AccountBoxIcon />, name: "Profile" },
    { to: "/settings", icon: <SettingsIcon />, name: "settings" },
  ];
  const { logout } = useLogout();

  const location = useLocation();

  const currentPage = location.pathname;
const [open, setOpen] = React.useState(false);
const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};


const [openInvite,setOpenInvite] = useState(false);
const handleClickOpenInvite = () => {
  setOpenInvite(true);
};

const handleCloseInvite = () => {
  setOpenInvite(false);
};


  const drawer = (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
    
        <Toolbar />
        <Divider />
        <List>
          {pages.map((item, index) => (
            <ListItem key={item.name} disablePadding>
              <Link
                to={item.to}
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  width: "100%",
                  backgroundColor:currentPage === item.to &&'#eaebef'
                  // display: "inline",
                  // borderBottom: "1px solid black"
                }}
              >
                <ListItemButton >
                  <ListItemIcon >{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        {/* <ListItemButton onClick={handleClickOpenInvite}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"Practice with a Friend"} />
        </ListItemButton> */}
        </List>
        <Divider />
        {settings.map((item, index) => (
          <ListItem key={item.name} disablePadding>
            <Link
              to={item.to}
              style={{
                textDecoration: "none",
                color: "inherit",
                width: "100%",
              }}
            >
              <ListItemButton >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <Divider sx={{ mb: "auto" }} style={{ marginBottom: "auto" }} />
      </div>
      
      <div>
        {/* <ListItemButton onClick={handleClickSnack}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={"Practice with a friend"} />
        </ListItemButton> */}
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary={"Feedback"} />
        </ListItemButton>
        <ListItemButton onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItemButton>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
     {openInvite && <InviteFriendModal 
          open={openInvite}
          handleClose={handleCloseInvite}
          setmodal={setOpenInvite}
      />}
      <Feedback open={open} handleClose={handleClose} user={user}/>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
DrawerBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerBar;
