import React from 'react';
import { Box, Skeleton, Typography, Button } from '@mui/material';

const ProfileSkeleton = () => {
  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: { xs: '100%', sm: 600, md: 800 }, 
      p: 2, 
      border: '1px solid #e0e0e0', 
      borderRadius: 2,
      mx: 'auto' // Center the box
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Skeleton variant="circular" width={60} height={60} sx={{ mr: 2 }} />
        <Box sx={{ width: '100%' }}>
          <Skeleton width="60%" height={24} />
          <Skeleton width="40%" height={20} />
        </Box>
      </Box>
      
      <Box sx={{ mb: 2 }}>
        <Skeleton width="80%" height={20} />
        <Skeleton width="70%" height={20} />
      </Box>
      
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
        {[...Array(6)].map((_, index) => (
          <Skeleton key={index} variant="rounded" width={100} height={32} />
        ))}
      </Box>
      
      <Skeleton variant="rounded" width="100%" height={48} />
    </Box>
  );
};

export default ProfileSkeleton;