import React, { useState, useEffect, useRef } from "react";
import styles from "./Filters.module.css";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import Fuse from 'fuse.js'

export default function Categories({category,menu,setFilter,filter,setFilterArray,filterArray,matchUsers}) {

  const [chosen,setChosen]= useState(null)
  const [open, setOpen] = useState(false);
  const [options,setOptions] = useState(menu.slice(0, 100))
  const [filterWord,setFilterWord]= useState('')
  const anchorRef = useRef(null);


  const handleSearch= (e)=>{
    setFilterWord(e.target.value)
    const options = {
      includeScore: true,
      
    }
    if(e.target.value=== ''||e.target.value=== ' '){
      setOptions(menu)

     
    }else{
      const fuse = new Fuse(menu, options)
      
      const result = fuse.search(e.target.value)
      const filtered = result.filter((item)=> item.score<0.2)
      const fixed = filtered.map((item)=>item.item)
      setOptions(fixed)
      console.log(fixed)
    }
  }
  const handleToggle = () => {
    console.log("handleToggle")
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, Field) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    if(Field){

      const fil = filter
      fil[category] = Field
      setFilter({...fil})
      let arr = filterArray
      const index = arr.indexOf(chosen);
      if (index > -1 ) { 
        arr.splice(index, 1);
      }
      arr.push(Field)
      setFilterArray([...arr])
      setChosen(Field)
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const clear= (event)=>{
    event.stopPropagation();
    setFilterWord('')
    const fil = filter
    fil[category] = null
    setFilter({...fil})
    let arr = filterArray
    const index = filterArray.indexOf(chosen); 
    if (index > -1) { 
        arr.splice(index, 1);
        setFilterArray([...arr])
    }
    if(matchUsers){
      matchUsers(true)
    }
  }
  

  return (
    <>
      <div className={styles.catergory} ref={anchorRef}>
        <button className={filter[category] ? styles.buttonChosen : styles.button} onClick={handleToggle}>
          {filter[category]  ? (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: 20, m: 0, p: 1, color: "#4960a7" }}
                onClick={handleToggle}
              >
                {filter[category] }
              </Typography>
              <ClearIcon
          
                sx={{ pt: 1, pb: 1, pr: 1, color: "#4960a7",height:40,width:40,mt:0.5,zIndex:100  }}
                onClick={clear}
              />
            </>
          ) : (
            <>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: 20, m: 0, p: 1 }}
                
              >
                {category}
              </Typography>
              <ArrowDropDownIcon
                sx={{ pt: 1, pb: 1, pr: 1,height:33,width:33}}
              
              />
            </>
          )}
        </button>
      </div>

      <Popper
      sx={{zIndex: 100}}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
           <div className={styles.menu}>
               { category !=="Availability" &&<input type="text" className={styles.search} onChange={handleSearch} placeholder="search"/>}
            <div className={styles.searchResults}>

                  {options.map((fie) =>
                    filter[category]  === fie ? (
                      <MenuItem
                      key={fie}
                      onClick={(e) => handleClose(e, fie)}
                      sx={{ color: "#4960a7" }}
                      >
                        {fie}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={fie}
                        onClick={(e) => handleClose(e, fie)}
                        sx={{ color: "black" }}
                      >
                        {fie}
                      </MenuItem>
                    )
                  )}
                  {options.length ===0 &&(
                    <MenuItem
                    onClick={(e) => handleClose(e, filterWord)}
                    sx={{ color: "black" }}
                    >
                   Click here to search for <br/> "{filterWord}"
                  </MenuItem>
                   
                  )}
                  </div>
           </div>
                  
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
