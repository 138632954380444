let arr = [
    
    'microsoft Office',
    'Backend',
    'frontend',
    "Angular",
    "Microservices",
    "Azure",
    "data structures and algorithms",
    "AmazonWebServices",
    "Vue",
    "googleCloud",
    "jQuery",
    "WebSockets",
    "React",
    "React Native",
    "Docker",
    "NodeJs",
    "ExpressJs",
    "Mongodb",
    "Next",
    "Nuxt",
    "Redux",
    "Spring",
    "Hibernate",
    "GitHub",
    "Struts",
    "Google web toolkit",
    "JavaServer Faces",
    "Grails",
    "Vaadin",
    "Blade",
    "Play",
    "Ember",
    "Meteor",
    "Polymer",
    "Aurelia",
    "Backbone",
    "C",
    "Python",
    "Java",
    "C++",
    "C#",
    "JavaScript",
    "PHP",
    "SQL",
    "Groovy",
    "Classic Visual Basic",
    "Fortran",
    "R",
    "Ruby",
    "Swift",
    "Matlab",
    "Go",
    "Perl",
    "SASS",
    "pascal",
    "Delphi",
    "Objective-C",
    "Rust",
    "Julia",
    "Ada", 
    "Lisp",
    "Dart",
    "Scala",
    "COBOL",
    "ABAP",
    "Kotlin",
    "Lua",
    "TypeScript",
    "HTML",
    "CSS",
    "Visual Basic .NET",
    "Assembly language",
    "F#",
    "Scheme",
    "Prolog",
    "Bash",
   
  
]

export default arr