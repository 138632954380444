import React from 'react';
import styles from './RequestList.module.css';
import RequestCard from './RequestCard';
import RequestCardMobile from './RequestCardMobile';
const RequestList = ({ documents:requests, handleDelete, accept, success, loading }) => {
  console.log("🚀 ~ documents:", requests)


  return (
    <div className={styles.container}>
      {/* <h2 className={styles.title}>Upcoming interviews</h2> */}
      
      {/* Desktop View */}
      {/* <div className={styles.tableContainer}>
      <div className={styles.tableHeader}>
        <div className={styles.column}>User</div>
        <div className={styles.column}>Role</div>
        <div className={styles.column}>When</div>
        <div className={styles.column}>Interview Type</div>
        <div className={styles.column}>Topic</div>
        <div className={styles.column}>action</div>
      </div>
      
      {requests.map((request, index) => (
        <div key={index} className={styles.interviewRow}>
          <RequestCard index={index} request={request} handleDelete={handleDelete} accept={accept} success={success} loading={loading}/>
        </div>
      ))}
      
      </div> */}
      {/* Mobile View */}
      <div className={styles.mobileView}>
        {requests.map((request, index) => 
 <RequestCardMobile key={index} index={index} request={request} handleDelete={handleDelete} accept={accept} success={success} loading={loading}/>
       
        )}
      </div>
    </div>
  );
};

export default RequestList;
