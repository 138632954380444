import { useEffect, useState } from "react";
import { 
  Box, 
  Button, 
  CircularProgress, 
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import { green } from '@mui/material/colors';
import { Link } from "react-router-dom";
import styles from './RequestCardMobile.module.css';
import { format, formatDistanceToNowStrict } from 'date-fns';

const RequestCardMobile = ({ 
  request, 
  handleDelete, 
  accept, 
  success, 
  loading, 
  index 
}) => {
    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = format(date, 'EEE, MMM d');
        const formattedTime = format(date, 'h:mm a');
        const relativeTime = formatDistanceToNowStrict(date, { addSuffix: true });
      
        return `${formattedDate} at ${formattedTime} (${relativeTime})`;
      };
  const time = formatDateTime(request.time.timestamp);
  const interviewTypeDisplay = request.interviewType.toLowerCase().includes("interview") 
    ? request.interviewType.toUpperCase()
    : `${request.interviewType.toUpperCase()} INTERVIEW`;

  return (
    <Card className={styles.mobileContent}>
      <Box className={styles.roleSection}>
        <WorkIcon color="primary" />
        <Typography variant="h6" className={styles.roleTitle}>
          {`Role: ${request.role}`}
        </Typography>
      </Box>
      <CardContent>
        <Box className={styles.mobileRow}>
          <Box display="flex" alignItems="center" gap={1}>
            <PersonIcon color="action" />
            <Typography variant="body2" color="textSecondary">Peer</Typography>
          </Box>
          <Link to={`/profile/${request.userSendingRequestId}`} className={styles.link}>
            <Typography variant="body1" color="primary">{request.userSendingRequestName}</Typography>
          </Link>
        </Box>
        <Divider />
        
        <Box className={styles.mobileRow}>
          <Box display="flex" alignItems="center" gap={1}>
            <EventIcon color="action" />
            <Typography variant="body2" color="textSecondary">When</Typography>
          </Box>
          <Typography variant="body1">{time}</Typography>
        </Box>
        <Divider />
        
        <Box className={styles.mobileRow}>
          <Box display="flex" alignItems="center" gap={1}>
            <AssignmentIcon color="action" />
            <Typography variant="body2" color="textSecondary">Interview Type</Typography>
          </Box>
          <Typography variant="body1">{interviewTypeDisplay}</Typography>
        </Box>
        
        {request.topic && (
          <>
            <Divider />
            <Box className={styles.mobileRow}>
              <Typography variant="body2" color="textSecondary">Topic</Typography>
              <Typography variant="body1">{request.topic}</Typography>
            </Box>
          </>
        )}
      </CardContent>
      
      <CardActions className={styles.mobileActions}>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDelete(index)}
          fullWidth
        >
          Ignore
        </Button>
        <Box position="relative" width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={() => accept(index)}
            disabled={loading === index}
            fullWidth
          >
            Accept
          </Button>
          {loading === index && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default RequestCardMobile;
