import React from "react";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 400 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 30,
    margin: "-10px 0px",
    backgroundColor: theme.palette.mode === "green" ? "green" : "#faad41",
  },
}));

const Bar = ({ value }) => {
  return <BorderLinearProgress variant="determinate" value={value} />;
};

export default Bar;
