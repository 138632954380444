import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
//icons
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import BusinessIcon from "@mui/icons-material/Business";
import ScienceIcon from "@mui/icons-material/Science";
import Avatar from "@mui/material/Avatar";

import { useState } from "react";
export default function CardTop({user,styles,handleReviewModalOpen}) {
  const [imageLoaded, setImageLoaded] = useState(false);
    return <div className={styles.cardTopContainer}>
      <div className={styles.imageContainer}>


      {(!imageLoaded || user.photoURL) &&     <Avatar
              sx={{ display: "inline-flex", width: 80, height: 80 }}
              aria-label="recipe"
              src={user.photoURL}
            ></Avatar>}

         {!user.photoURL&&    <img onLoad={() => setImageLoaded(true)}
 style={{ display: "inline-flex"}}  src={`https://robohash.org/${user.displayName}.png?size=100x100`} ></img>}
  
        <div className={styles.rating} >
          <div onClick={handleReviewModalOpen} style={{cursor:"pointer"}}>
            <Rating name="read-only" value={user.rating.rating} readOnly />
            {/* <span style={{ fontWeight: 100 }}>{`(${
          user.rating.numberOfReviews ? user.rating.numberOfReviews : 0
        })`}</span> */}
          </div>
          <div className={styles.yoe}>
            {/* {user.displayName} */}
            {user.yoe >= 7 && (
              <Box sx={{ color: "error.main" }}>YOE: {user.yoe}</Box>
            )}
            {user.yoe > 3 && user.yoe < 7 && (
              <Box sx={{ color: "warning.main" }}>YOE: {user.yoe}</Box>
            )}
            {user.yoe <= 3 && (
              <Box sx={{ color: "success.main" }}>YOE: {user.yoe}</Box>
            )}
          </div>
        </div>
      </div>
  
      <div className={styles.position}>
        {user.currentPosition && (
          <h3 className={styles.miniContainer}>
            {user.currentPosition}
            <WorkIcon sx={{ color: "#4960A7" }} />
          </h3>
        )}
        {user.currentCompany && (
          <h3 className={styles.miniContainer}>
            {user.currentCompany}
  
            <BusinessIcon sx={{ color: "#4960A7" }} />
          </h3>
        )}
      </div>
      <div className={styles.education}>
        <h3 className={styles.miniContainer}>
          {user.educationLevel.replace("degree", " in ") + user.areaOfStudy}
          <ScienceIcon sx={{ color: "#4960A7" }} />
        </h3>
        <h3 className={styles.miniContainer}>
          {user.university}
          <SchoolIcon sx={{ color: "#4960A7" }} />
        </h3>
      </div>
    </div>;
  }
  
  