import React, { useState, useEffect } from "react";

import styles from "./Index.module.css";
import { Alert, Button, Container, TextField, Typography } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, updateProfile } from "firebase/auth";

import jobList from "assets/jobList";
import Role from "./TargetRole";
import CurrentJob from "./CurrentJob";
import InterviewTypes from "./InterviewTypes";
import Topics from "./Skills";
import axios from "axios";
import { useAuthContext } from "hooks/useAuthContext";
import { useUpdate } from "hooks/useUpdate";
import { useNavigate } from "react-router-dom";
import Education from "./Education";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CurrentCompany from "./CurrentCompany";
import Availability from "./Availability";
import getDocument from "hooks/useDoc";

// import random_name from "node-random-name"
import randomName from "assets/namesList"

import useAnalytics from "src/assets/useAnalytics"
import Aboutme from "./AboutMe";
import mixpanel from "mixpanel-browser";
import ExperienceLevel from "./ExperienceLevel";
import Bar from "./components/Bar";

const Index = ({ setProfileNotDone, setNewUser }) => {
  const { update, success } = useUpdate("users");
  const { documentArrayPush } = useUpdate("global");
  const { update:updatePrivate } = useUpdate("privateUsers");
  const [pages, setPages] = useState([
    "role",
    "education",
    "experience",
    "About",
    "availability",
  ]);
  const [currentPage, setCurrentPage] = useState(pages[0]);
  const [PageAnimation, setPageAnimation] = useState("form");
  const { authIsReady, user: userAuth, dispatch, userData } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [bar, setBar] = useState(0);
  const navigate = useNavigate();
  const {logEvent,identify} = useAnalytics()

  const [isStudent, setIsStudent] = useState(false);
  const [suggestedSkills, setSuggestedSkills] = useState(null);
  const [user, setUser] = useState({
    targetRole: "",
    interviewTypes: [],
    topics: [],
    currentPosition: "",
    currentCompany: "",
    educationLevel: "",
    areaOfStudy: "",
    university: "",
    bootCampGraduate: false,
    bootCamp: "",
    yoe: 0,
    availability: [
      {
        "day": "Mon",
        "hour": []
      },
      {
        "day": "Tue",
        "hour": []
      },
      {
        "day": "Wed",
        "hour": []
      },
      {
        "day": "Thu",
        "hour": []
      },
      {
        "day": "Fri",
        "hour": []
      },
      {
        "day": "Sat",
        "hour": []
      },
      {
        "day": "Sun",
        "hour": []
      }
    ],
    aboutMe: "",
  });
  const handleNext = async (e, student, value) => {
    if (e) e.preventDefault();
    console.log("next",pages);
    // setLoading(true)
    logEvent("onBoarding-"+currentPage);

    if(currentPage === "experience" && user.currentPosition === "unemployed"){
      setIsStudent(true);
      handleNext(false, true);
    }
    let newPages = pages; 
    // when role answer came back we either
    // we either add "interviews or we delete it"
    if (currentPage === "role") {
      let role = value || user.targetRole
      let foundInfo = jobList.find((job) => job.title.toLowerCase() === role.toLowerCase())
      // TODO:fix jobs and add to them hasTechInterviews
      if(false){
      // if(foundInfo?.category){
        console.log("🚀 ~ handleNext ~ foundInfo:", foundInfo)
        setUser((originalUser) => {
          let obj = {
            ...originalUser,
            category: foundInfo.category,
            subCategory: foundInfo.subCategory,
            hasTechInterview: foundInfo.interview,
            interviewTypes: ["General Interview"],
          }
          if(foundInfo.interview){
            obj.interviewTypes = ["Behavioral Interview", "Technical Interview"]
          }
          console.log("🚀 ~ setUser ~ obj:", obj)
          return obj
        });
      }else{
      axios.post("https://peerfect-api-production.up.railway.app/role", { role })
        .then(({ data }) => {
          let parsed = JSON.parse(data.response);
          console.log("🚀 ~ file: Index.js:102 ~ axios.post ~ parsed:", parsed);
            setUser((originalUser) => {
              let obj = {
                ...originalUser,
                category: parsed.category,
                subCategory: parsed.subCategory,
                hasTechInterview: parsed.interview,
                interviewTypes: ["General Interview"],
              }
              if(parsed.interview){
                obj.interviewTypes = ["Behavioral Interview", "Technical Interview"]
              }
              console.log("🚀 ~ setUser ~ obj:", obj)
              return obj
            });
     
        })
        .catch((error) => {
          console.error(error,"error getting role info");
        })
      }

 

    }
    let devJobs = [
      "devops engineer",
      "software engineer",
      "software developer",
      "front-end developer",
      "back-end developer",
      "full-stack developer"
    ]

    if (currentPage === "experience") {
      let index = pages.indexOf("experience");
      if (!student) {
        console.log("is student");

        if (devJobs.includes(user.targetRole.toLowerCase())) {
          newPages.splice(index + 1, 0, "company", "topics");
        } else {
          newPages.splice(index + 1, 0, "company");
        }
        setPages(newPages);
        setUser((originalUser) => {
          return { ...originalUser, isStudent: false };
        });
      } else {
        newPages = pages;
        if (devJobs.includes(user.targetRole.toLowerCase())) {
          newPages.splice(index + 1, 0, "topics");
        } else {
          if (pages.includes("topics")) {
            console.log(pages);
            newPages = newPages.filter((item) => item !== "topics");
          }
        }
        if (pages.includes("company")) {
          console.log(pages);
          newPages = newPages.filter((item) => item !== "company");
        }
        setPages(newPages);

        setUser((originalUser) => {
          return { ...originalUser, isStudent: true };
        });
      }
    }

    if (currentPage === pages[pages.length - 1]) {
      handleSubmit();
      return;
    }
    let currentIndex = newPages.indexOf(currentPage);
    // setCurrentPage
    let newPage = newPages[currentIndex + 1];
  if(currentPage === newPage){
    newPages = [...new Set(newPages)]
    newPage = newPages[currentIndex+1]
  }

    setPageAnimation("nextVanish");
    await new Promise((resolve) => setTimeout(resolve, 300));
    setPageAnimation("nextAppear");
    setCurrentPage(newPage);

    if (newPage === "availability") {
      setBar(100);
    } else {
      let newPageIndex = pages.indexOf(newPage)

      let barLevel =  (newPageIndex+1)/pages.length*100
      if(newPageIndex <3){
       barLevel = barLevel-15
      }
       setBar(barLevel);
    }
  };

  const handlePrevious = () => {
    let currentIndex = pages.indexOf(currentPage);
    let newPage = pages[currentIndex - 1];
    setPageAnimation("previousVanish");
    setTimeout(() => {
      setPageAnimation("previousAppear");
      if (newPage === "role") {
        setBar(0);
      } else {
        let newPageIndex = pages.indexOf(newPage)
        let barLevel =  (newPageIndex+1)/pages.length*100
   
        setBar(barLevel);
      }
 
      setCurrentPage(newPage);
    }, 300);
  };




  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
  
   if(user.availability.every(day => day.hour.length === 0) || currentPage !== "availability"){
    setCurrentPage("availability");
    setBar(100);
    return
   }

    if (loading) return;
    setLoading(true);
    setError(null);
    console.log(user);
    try {
      let obj = {
        displayName:randomName,
        targetCompany: "",
        experience: "",
        interviewTypes: ["General Interview"],
        category: "",
        subCategory: "",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        costumInterviews: false,
        codingDifficulty: 4,
        codingLevel: null,
        previousCodingProblems: [],
        active:true,
        profile:true,
        lastTimeActive:Date.now(),
        activeDuration: Date.now() + 604800000,

        ...user,
      
      };

      if(obj.hasTechInterview){
        obj.interviewTypes = ["Behavioral Interview", "Technical Interview"]
      }
      let devJobs =  [
        "devops engineer",
        "software engineer",
        "software developer",
        "front-end developer",
        "frontend developer",
        "back-end developer",
        "full-stack developer"
      ]
      if (devJobs.includes(obj.targetRole.toLowerCase())) {
        obj.interviewTypes = ["Behavioral Interview", "Technical Interview","Coding Interview","System Design"]
      }
      obj.onBoarding = true;
      console.log(obj);
      // return
      await update(userAuth.uid, obj);
      updatePrivate(userAuth.uid,{displayName:randomName})
      const auth = getAuth();
      //add profile propterties
      logEvent("onBoarding-Submit",{
        targetRole:obj.targetRole||"",
        targetCompany:obj.targetCompany||"",
        educationLevel:obj.educationLevel||"",
        areaOfStudy:obj.areaOfStudy||"",
        university:obj.university||"",
        bootCampGraduate:obj.bootCampGraduate||"",
        bootCamp:obj.bootCamp||"",
        currentPosition:obj.currentPosition||"",
        currentCompany:obj.currentCompany||"",
        experience:obj.experience||"",
        interviewTypes:obj.interviewTypes||"",
        category:obj.category||"",
        subCategory:obj.subCategory||"",
      });
      mixpanel.people.set({
        targetRole:obj.targetRole||"",
        targetCompany:obj.targetCompany||"",
        educationLevel:obj.educationLevel||"",
        currentPosition:obj.currentPosition||"",
        experience:obj.experience||"",
        category:obj.category||"",
        subCategory:obj.subCategory||"",
        displayName: randomName
      })
       updateProfile(auth.currentUser, {
        displayName: randomName,
      });
      console.log("runing");
      let privateData = {
        displayName:randomName,
        acceptedUsers: [],
        ignoredUsers: [],
        absences: 0,
      };
      const functions = getFunctions();
      const questionsGenerate = httpsCallable(
        functions,
        "generatePracticeQuestions"
      );
      questionsGenerate({ ...obj, userId: userAuth.uid });
      dispatch({ type: "profile", payload: { privateData, userData: obj } });
      setProfileNotDone(false);
      setNewUser(false);
      navigate("/browse");
      console.log("success");
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(true);
    }
  };









  var props = {
    isStudent,
    setIsStudent,
    handleSubmit,
    user,
    setUser,
    handleNext,
    handlePrevious,
    loading,
    suggestedSkills,
  };


  let pagesMap={

  }
  return (
    <div className={styles.container}>
      {/* <Container> */}

      <div className={styles.filler}></div>
      <div className={styles.top}>
        {currentPage !== "role" && (
          <div onClick={handlePrevious} style={{ cursor: "pointer" }}>
            <ArrowBackIcon sx={{ height: 30, width: 30 }} />
          </div>
        )}
        <div className={styles.bar}>
          {/* <BorderLinearProgress variant="determinate" value={bar} /> */}
          <Bar value={bar}/>
        </div>
      </div>

      <form
        onSubmit={bar < 100 ? handleNext : handleSubmit}
        className={`${styles[PageAnimation]} ${styles.form}`}
      >
        {/* <Availability {...props}/> */}
        {/* const [pages,setPages] =useState(["role","education","experience","availability"]) */}

        {currentPage === "role" && <Role {...props} />}
        {currentPage === "education" && <Education {...props} />}
        {currentPage === "experience" && <CurrentJob {...props} />}
        {currentPage === "company" && <CurrentCompany {...props} />}
        {currentPage === "interviews" && <InterviewTypes {...props} />}
        {currentPage === "topics" && <Topics {...props} />}
        {currentPage === "About" && <Aboutme {...props} />}
        {currentPage === "availability" && <Availability {...props} />}
        {currentPage === "experienceLevel" && <ExperienceLevel {...props} />}

        <div
          className={styles.buttonContainer}
          style={{
            justifyContent:
              (currentPage === "experience" ||currentPage === "About"||currentPage === "company" )? "space-between" : "center",
          }}
        >
          {(currentPage === "experience" ||currentPage === "company" )&& (
            <Button
              onClick={() => {
                setIsStudent(true);
                handleNext(false, true);
              }}
              type="button"
              color="success"
              variant="contained"
              sx={{ fontSize: 18, width: 200 }}
            >
             Skip
            </Button>
          )}
          {currentPage === "About" && (
            <Button
              onClick={() => {
                handleNext();
              }}
              type="button"
              color="success"
              variant="contained"
              sx={{ fontSize: 18, width: 200 }}
            >
              {" "}
              Skip
            </Button>
          )}

          <Button type="submit" variant="contained" sx={{ fontSize: 18 }} disabled={(currentPage === "availability"&&user.availability.every(day => day.hour.length === 0))}>
            {currentPage !== "availability" && "Next"}
            {currentPage === "availability" && loading && "..."}
            {currentPage === "availability" && !loading && "Submit"}
          </Button>
        </div>
      </form>
      {error && (
        <Alert sx={{ mt: 2 }} severity="error">
          something wrong happened try again
        </Alert>
      )}
    </div>
  );
};

export default Index;
