let jobs = [
  { title: 'Software Engineer',
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
        "subCategory": "Software Development",
        interview:true
   },
  { title: 'software Developer',
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Software Development",
    interview:true

   },
  { title: 'data engineer',
    "category": "Science, Technology, Engineering, and Mathematics (STEM)",
    "subCategory": "Data Science",
    interview:true

   },
  { title: 'product manager' ,
    category: 'Business, Management, and Administration',
    subCategory: 'Project Management',
    interview:true


  },
  { title: 'data scientist',
    category: 'Science, Technology, Engineering, and Mathematics (STEM)',
    subCategory: 'Data Science',
    interview:true


   },
  { title: 'data analyst',
    category: 'Science, Technology, Engineering, and Mathematics (STEM)',
    subCategory: 'Data Science',
    interview:true



   },
  { title: 'customer service representative',
    category: 'Marketing, Sales, and Customer Service',
    subCategory: 'Customer Support'

   },
  { title: 'frontend developer',   
    category: 'Science, Technology, Engineering, and Mathematics (STEM)',
    subCategory: 'Software Development',
    interview:true

 },
  { title: 'administrative assistant',
    category: 'Business, Management, and Administration',
    subCategory: 'Operations Management'

   },
  { title: 'project manager',
    category: 'Business, Management, and Administration',
    subCategory: 'Project Management',
    interview:true


   },
  { title: 'web developer',
    category: 'Science, Technology, Engineering, and Mathematics (STEM)',
    subCategory: 'Software Development',
    interview:true


   },
  { title: 'accountant' ,
    category: 'Finance, Insurance, and Real Estate',
    subCategory: 'Accounting',
    interview:true


  },
{ title: 'account representative' ,
  category: 'Marketing, Sales, and Customer Service',
  subCategory: 'Sales'

},
{ title: 'actor' },
{ title: 'actuary' },
{ title: 'adhesive bonding machine tender' },
{ title: 'adjudicator' },
{ title: 'admin' },
{ title: 'administrative services manager' },
{ title: 'adult education teacher' },
{ title: 'advertising manager' },
{ title: 'advertising sales agent' },
{ title: 'aerobics instructor' },
{ title: 'aerospace engineer' },
{ title: 'aerospace engineering technician' },
{ title: 'agent' },
{ title: 'agricultural engineer' },
{ title: 'agricultural equipment operator' },
{ title: 'agricultural grader' },
{ title: 'agricultural inspector' },
{ title: 'agricultural manager' },
{ title: 'agricultural sciences teacher' },
{ title: 'agricultural sorter' },
{ title: 'agricultural technician' },
{ title: 'agricultural worker' },
{ title: 'air conditioning installer' },
{ title: 'air conditioning mechanic' },
{ title: 'air traffic controller' },
{ title: 'aircraft cargo handling supervisor' },
{ title: 'aircraft mechanic' },
{ title: 'aircraft service technician' },
{ title: 'airline copilot' },
{ title: 'airline pilot' },
{ title: 'ambulance dispatcher' },
{ title: 'ambulance driver' },
{ title: 'amusement machine servicer' },
{ title: 'anesthesiologist' },
{ title: 'animal breeder' },
{ title: 'animal control worker' },
{ title: 'animal scientist' },
{ title: 'animal trainer' },
{ title: 'animator' },
{ title: 'answering service operator' },
{ title: 'anthropologist' },
{ title: 'apparel patternmaker' },
{ title: 'apparel worker' },
{ title: 'arbitrator' },
{ title: 'archeologist' },
{ title: 'architect' },
{ title: 'architectural drafter' },
{ title: 'architectural manager' },
{ title: 'archivist' },
{ title: 'art director' },
{ title: 'art teacher' },
{ title: 'artist' },
{ title: 'assembler' },
{ title: 'astronomer' },
{ title: 'athlete' },
{ title: 'athletic trainer' },
{ title: 'atmospheric scientist' },
{ title: 'attendant' },
{ title: 'audio and video equipment technician' },
{ title: 'audio-visual and multimedia collections specialist' },
{ title: 'audiologist' },
{ title: 'auditor' },
{ title: 'author' },
{ title: 'auto damage insurance appraiser' },
{ title: 'automotive and watercraft service attendant' },
{ title: 'automotive glass installer' },
{ title: 'automotive mechanic' },
{ title: 'avionics technician' },
{ title: 'Barista' },
{ title: 'backend developer' },
{ title: 'baggage porter' },
{ title: 'bailiff' },
{ title: 'baker' },
{ title: 'barback' },
{ title: 'barber' },
{ title: 'bartender' },
{ title: 'basic education teacher' },
{ title: 'behavioral disorder counselor' },
{ title: 'bellhop' },
{ title: 'bench carpenter' },
{ title: 'bicycle repairer' },
{ title: 'bill and account collector' },
{ title: 'billing and posting clerk' },
{ title: 'biochemist' },
{ title: 'biological technician' },
{ title: 'biomedical engineer' },
{ title: 'biophysicist' },
{ title: 'blaster' },
{ title: 'blending machine operator' },
{ title: 'blockmason' },
{ title: 'boiler operator' },
{ title: 'boilermaker' },
{ title: 'bookkeeper' },
{ title: 'boring machine tool tender' },
{ title: 'brazer' },
{ title: 'brickmason' },
{ title: 'bridge and lock tender' },
{ title: 'broadcast news analyst' },
{ title: 'broadcast technician' },
{ title: 'brokerage clerk' },
{ title: 'budget analyst' },
{ title: 'building inspector' },
{ title: 'bus mechanic' },
{ title: 'business analyst',
  category: 'Business, Management, and Administration',
  subCategory: 'Business Analysis',
  interview:true


 },
{ title: 'business development representative' },
{ title: 'butcher' },
{ title: 'buyer' },
{ title: 'cabinetmaker' },
{ title: 'cafeteria attendant' },
{ title: 'cafeteria cook' },
{ title: 'camera operator' },
{ title: 'camera repairer' },
{ title: 'cardiovascular technician' },
{ title: 'cargo agent' },
{ title: 'carpenter' },
{ title: 'carpet installer' },
{ title: 'cartographer' },
{ title: 'cashier' },
{ title: 'caster' },
{ title: 'ceiling tile installer' },
{ title: 'cellular equipment installer' },
{ title: 'cement mason' },
{ title: 'channeling machine operator' },
{ title: 'chauffeur' },
{ title: 'checker' },
{ title: 'chef' },
{ title: 'chemical engineer' },
{ title: 'chemical plant operator' },
{ title: 'chemist' },
{ title: 'chemistry teacher' },
{ title: 'chief executive' },
{ title: 'child social worker' },
{ title: 'childcare worker' },
{ title: 'chiropractor' },
{ title: 'choreographer' },
{ title: 'civil drafter' },
{ title: 'civil engineer' },
{ title: 'civil engineering technician' },
{ title: 'claims adjuster' },
{ title: 'claims examiner' },
{ title: 'claims investigator' },
{ title: 'cleaner' },
{ title: 'clinical laboratory technician' },
{ title: 'clinical laboratory technologist' },
{ title: 'clinical psychologist' },
{ title: 'coating worker' },
{ title: 'coatroom attendant' },
{ title: 'coil finisher' },
{ title: 'coil taper' },
{ title: 'coil winder' },
{ title: 'coin machine servicer' },
{ title: 'college interview' },
{ title: 'commercial diver' },
{ title: 'commercial pilot' },
{ title: 'commodities sales agent' },
{ title: 'communications equipment operator' },
{ title: 'communications teacher' },
{ title: 'community association manager' },
{ title: 'community outreach specialist' },
{ title: 'community service manager' },
{ title: 'compensation and benefits manager' },
{ title: 'compliance officer' },
{ title: 'composer' },
{ title: 'computer hardware engineer' },
{ title: 'computer network architect' },
{ title: 'computer operator' },
{ title: 'computer programmer' },
{ title: 'computer science teacher' },
{ title: 'computer support specialist' },
{ title: 'computer systems administrator' },
{ title: 'computer systems analyst' },
{ title: 'concierge' },
{ title: 'conciliator' },
{ title: 'concrete finisher' },
{ title: 'conservation science teacher' },
{ title: 'conservation scientist' },
{ title: 'conservation worker' },
{ title: 'conservator' },
{ title: 'construction inspector' },
{ title: 'construction manager' },
{ title: 'construction painter' },
{ title: 'construction worker' },
{ title: 'continuous mining machine operator' },
{ title: 'convention planner' },
{ title: 'conveyor operator' },
{ title: 'cook' },
{ title: 'cooling equipment operator' },
{ title: 'copy marker' },
{ title: 'correctional officer' },
{ title: 'correctional treatment specialist' },
{ title: 'correspondence clerk' },
{ title: 'correspondent' },
{ title: 'cosmetologist' },
{ title: 'cost estimator' },
{ title: 'costume attendant' },
{ title: 'costumer success' },
{ title: 'counseling psychologist' },
{ title: 'counselor' },
{ title: 'courier' },
{ title: 'court reporter' },
{ title: 'craft artist' },
{ title: 'crane operator' },
{ title: 'credit analyst' },
{ title: 'credit checker' },
{ title: 'credit counselor' },
{ title: 'criminal investigator' },
{ title: 'criminal justice teacher' },
{ title: 'crossing guard' },
{ title: 'curator' },
{ title: 'custom sewer' },
{ title: 'customer service agent' ,
  category: 'Marketing, Sales, and Customer Service',
  subCategory: 'Customer Support'
},
{ title: 'cutter' },
{ title: 'cutting machine operator' },
{ title: 'cyber security' },
{ title: 'dancer' },
{ title: 'data entry keyer' },
{ title: 'data intern' },
{ title: 'data structures and algorithms' },
{ title: 'database administrator' },
{ title: 'decorating worker' },
{ title: 'delivery services driver' },
{ title: 'demonstrator' },
{ title: 'dental assistant' },
{ title: 'dental hygienist' },
{ title: 'dental laboratory technician' },
{ title: 'dentist' },
{ title: 'dermatologist' },
{ title: 'derrick operator' },
{ title: 'designer' },
{ title: 'desktop publisher' },
{ title: 'detective' },
{ title: 'developer' },
{ title: 'diagnostic medical sonographer' },
{ title: 'die maker' },
{ title: 'diesel engine specialist' },
{ title: 'dietetic technician' },
{ title: 'dietitian' },
{ title: 'dinkey operator' },
{ title: 'director' },
{ title: 'dishwasher' },
{ title: 'dispatcher' },
{ title: 'doctor',
  category: 'Healthcare and Medical Services',
  subCategory: 'Physician Services'


 },
{ title: 'door-to-door sales worker' },
{ title: 'drafter' },
{ title: 'dragline operator' },
{ title: 'drama teacher' },
{ title: 'dredge operator' },
{ title: 'DevOps engineer' },
{ title: 'dressing room attendant' },
{ title: 'dressmaker' },
{ title: 'drier operator' },
{ title: 'drilling machine tool operator' },
{ title: 'dry-cleaning worker' },
{ title: 'drywall installer' },
{ title: 'dyeing machine operator' },
{ title: 'earth driller' },
{ title: 'economics teacher' },
{ title: 'economist' },
{ title: 'editor' },
{ title: 'education administrator' },
{ title: 'electric motor repairer' },
{ title: 'electrical electronics drafter' },
{ title: 'electrical engineer' },
{ title: 'electrical equipment assembler' },
{ title: 'electrical installer' },
{ title: 'electrical power-line installer' },
{ title: 'electrician' },
{ title: 'electro-mechanical technician' },
{ title: 'elementary school teacher' },
{ title: 'elevator installer' },
{ title: 'elevator repairer' },
{ title: 'embalmer' },
{ title: 'emergency management director' },
{ title: 'emergency medical technician' },
{ title: 'engine assembler' },
{ title: 'engineer' },
{ title: 'engineering manager',
  category: 'Business, Management, and Administration',
  subCategory: 'Operations Management',
  interview:true


 },
{ title: 'engineering teacher' },
{ title: 'english language teacher' },
{ title: 'engraver' },
{ title: 'entertainment attendant' },
{ title: 'environmental engineer' },
{ title: 'environmental science teacher' },
{ title: 'environmental scientist' },
{ title: 'epidemiologist' },
{ title: 'escort' },
{ title: 'etcher' },
{ title: 'event planner' },
{ title: 'excavating operator' },
{ title: 'executive administrative assistant' },
{ title: 'executive secretary' },
{ title: 'exhibit designer' },
{ title: 'expediting clerk' },
{ title: 'explosives worker' },
{ title: 'extraction worker' },
{ title: 'fabric mender' },
{ title: 'fabric patternmaker' },
{ title: 'fabricator' },
{ title: 'faller' },
{ title: 'family practitioner' },
{ title: 'family social worker' },
{ title: 'family therapist' },
{ title: 'farm advisor' },
{ title: 'farm equipment mechanic' },
{ title: 'farm labor contractor' },
{ title: 'farmer' },
{ title: 'farmworker' },
{ title: 'fashion designer' },
{ title: 'fast food cook' },

{ title: 'fence erector' },
{ title: 'fiberglass fabricator' },
{ title: 'fiberglass laminator' },
{ title: 'file clerk' },
{ title: 'filling machine operator' },
{ title: 'film and video editor' },
{ title: 'financial analyst',

  category: 'Finance, Insurance, and Real Estate',
  subCategory: 'Financial Analysis',
  interview:true


 },
{ title: 'financial examiner' },
{ title: 'financial manager' },
{ title: 'financial services sales agent' },
{ title: 'fine artist' },
{ title: 'fire alarm system installer' },
{ title: 'fire dispatcher' },
{ title: 'fire inspector' },
{ title: 'fire investigator' },
{ title: 'firefighter' },
{ title: 'fish and game warden' },
{ title: 'fish cutter' },
{ title: 'fish trimmer' },
{ title: 'fisher' },
{ title: 'fitness studies teacher' },
{ title: 'fitness trainer' },
{ title: 'flight attendant' },
{ title: 'floor finisher' },
{ title: 'floor layer' },
{ title: 'floor sander' },
{ title: 'floral designer' },
{ title: 'food batchmaker' },
{ title: 'food cooking machine operator' },
{ title: 'food preparation worker' },
{ title: 'food science technician' },
{ title: 'food scientist' },
{ title: 'food server' },
{ title: 'food service manager' },
{ title: 'food technologist' },
{ title: 'foreign language teacher' },
{ title: 'foreign literature teacher' },
{ title: 'forensic science technician' },
{ title: 'forest fire inspector' },
{ title: 'forest fire prevention specialist' },
{ title: 'forest worker' },
{ title: 'forester' },
{ title: 'forestry teacher' },
{ title: 'forging machine setter' },
{ title: 'foundry coremaker' },
{ title: 'freight agent' },
{ title: 'freight mover' },
{ title: 'fundraising manager' },
{ title: 'funeral attendant' },
{ title: 'Full-Stack Developer',
  category: 'Science, Technology, Engineering, and Mathematics (STEM)',
  subCategory: 'Software Development',
  interview:true



 },
{ title: 'funeral director' },
{ title: 'funeral service manager' },
{ title: 'furnace operator' },
{ title: 'furnishings worker' },
{ title: 'furniture finisher' },
{ title: 'gaming booth cashier' },
{ title: 'gaming cage worker' },
{ title: 'gaming change person' },
{ title: 'gaming dealer' },
{ title: 'gaming investigator' },
{ title: 'gaming manager' },
{ title: 'gaming surveillance officer' },
{ title: 'garment mender' },
{ title: 'garment presser' },
{ title: 'gas compressor' },
{ title: 'gas plant operator' },
{ title: 'gas pumping station operator' },
{ title: 'general manager' },
{ title: 'general practitioner' },
{ title: 'helpdesk game support' },
{ title: 'human resource',       category: 'Business, Management, and Administration',
  subCategory: 'Human Resources'
},
{ title: 'interior design' },
{ title: 'it support' },
{ title: 'machine learning engineer',
  category: 'Science, Technology, Engineering, and Mathematics (STEM)',
  subCategory: 'Data Science',
  interview:true


 },
 {title:"finance",

  category: 'Finance, Insurance, and Real Estate',
  subCategory: 'Finance',
  interview:true


 },
{ title: 'mechanical engineer' },
{ title: 'mechatronic technologist' },
{ title: 'medical school',

  category: 'Healthcare and Medical Services',
  subCategory: 'Medical Administration'

 },
{ title: 'oracle database administrator' },
{ title: 'pharmacovigilance' },
{ title: 'pre-med' },
{ title: 'premed' },
{ title: 'probation officer' },
{ title: 'procurement clerk' },
{ title: 'producer' },
{ title: 'product promoter' },
{ title: 'production clerk' },
{ title: 'production manager' },
{ title: 'production worker' },
{ title: 'proofreader' },
{ title: 'property coordinator' },
{ title: 'property manager' },
{ title: 'prosthetist' },
{ title: 'psychiatric aide' },
{ title: 'psychiatric technician' },
{ title: 'psychiatrist' },
{ title: 'psychologist' },
{ title: 'psychology teacher' },
{ title: 'public relations manager' },
{ title: 'public relations specialist' },
{ title: 'pump operator' },
{ title: 'purchasing agent' },
{ title: 'purchasing manager' },
{ title: 'radiation therapist' },
{ title: 'radio announcer' },
{ title: 'radio equipment installer' },
{ title: 'radio operator' },
{ title: 'radiologic technician' },
{ title: 'radiologic technologist' },
{ title: 'rail car repairer' },
{ title: 'rail transportation worker' },
{ title: 'rail yard engineer' },
{ title: 'rail-track laying equipment operator' },
{ title: 'railroad brake operator' },
{ title: 'railroad conductor' },
{ title: 'railroad police' },
{ title: 'rancher' },
{ title: 'real estate appraiser' },
{ title: 'real estate broker' },
{ title: 'real estate manager' },
{ title: 'real estate sales agent' },
{ title: 'receiving clerk' },
{ title: 'receptionist' },
{ title: 'record clerk' },
{ title: 'recreation worker' },
{ title: 'recreational therapist' },
{ title: 'recreational vehicle service technician' },
{ title: 'recycling coordinator' },
{ title: 'referee' },
{ title: 'refractory materials repairer' },
{ title: 'refrigeration installer' },
{ title: 'refrigeration mechanic' },
{ title: 'registered nurse' },
{ title: 'rehabilitation counselor' },
{ title: 'reinforcing iron worker' },
{ title: 'reinforcing rebar worker' },
{ title: 'religion teacher' },
{ title: 'religious activities director' },
{ title: 'religious worker' },
{ title: 'rental clerk' },
{ title: 'repair worker' },
{ title: 'reporter' },
{ title: 'researcher' },
{ title: 'residential advisor' },
{ title: 'resort desk clerk' },
{ title: 'respiratory therapist' },
{ title: 'respiratory therapy technician' },
{ title: 'retail salesperson',
  category: 'Retail, Wholesale, and E-commerce',
  subCategory: 'Retail Sales'

 },
{ title: 'rigger' },
{ title: 'rock splitter' },
{ title: 'rolling machine setter' },
{ title: 'roof bolter' },
{ title: 'roofer' },
{ title: 'rotary drill operator' },
{ title: 'roustabout' },
{ title: 'safety engineer' },
{ title: 'sailor' },
{ title: 'sales engineer' },
{ title: 'sales manager' },
{ title: 'sales representative' ,
  category: 'Marketing, Sales, and Customer Service',
  subCategory: 'Sales'

},
{ title: 'sampler' },
{ title: 'sawing machine operator' },
{ title: 'scaler' },
{ title: 'school bus driver' },
{ title: 'school psychologist' },
{ title: 'school social worker' },
{ title: 'scout leader' },
{ title: 'sculptor' },
{ title: 'secondary school teacher' },
{ title: 'secretary' },
{ title: 'securities sales agent' },
{ title: 'security guard' },
{ title: 'security system installer' },
{ title: 'segmental paver' },
{ title: 'self-enrichment education teacher' },
{ title: 'semiconductor processor' },
{ title: 'senior analyst' },
{ title: 'septic tank installer' },
{ title: 'set designer' },
{ title: 'sewer pipe cleaner' },
{ title: 'sewing machine operator' },
{ title: 'shampooer' },
{ title: 'shaper' },
{ title: 'sheet metal worker' },
{ title: 'sheriff\'s patrol officer' },
{ title: 'ship captain' },
{ title: 'ship engineer' },
{ title: 'ship loader' },
{ title: 'shipmate' },
{ title: 'shipping clerk' },
{ title: 'shoe machine operator' },
{ title: 'shoe worker' },
{ title: 'short order cook' },
{ title: 'signal repairer' },
{ title: 'singer' },
{ title: 'ski patrol' },
{ title: 'skincare specialist' },
{ title: 'slaughterer' },
{ title: 'slicing machine tender' },
{ title: 'slot supervisor' },
{ title: 'social science research assistant' },
{ title: 'social sciences teacher' },
{ title: 'social scientist' },
{ title: 'social service assistant' },
{ title: 'social work teacher' },
{ title: 'social worker' },
{ title: 'sociologist' },
{ title: 'sociology teacher' },
{ title: 'soil scientist' },
{ title: 'solderer' },
{ title: 'sorter' },
{ title: 'sound engineering technician' },
{ title: 'space scientist' },
{ title: 'special education teacher' },
{ title: 'speech-language pathologist' },
{ title: 'sports book runner' },
{ title: 'sports entertainer' },
{ title: 'sports performer' },
{ title: 'stationary engineer' },

{ title: 'statistical assistant' },
{ title: 'statistician' },
{ title: 'steamfitter' },
{ title: 'sterile processing technician' },
{ title: 'stock clerk' },
{ title: 'stock mover' },
{ title: 'stonemason' },
{ title: 'street vendor' },
{ title: 'structural iron worker' },
{ title: 'structural metal fabricator' },
{ title: 'structural steel worker' },
{ title: 'stucco mason' },
{ title: 'student' },
{ title: 'substance abuse counselor' },
{ title: 'substance abuse social worker' },
{ title: 'subway operator' },
{ title: 'supply chain planning manager' },
{ title: 'surfacing equipment operator' },
{ title: 'surgeon' },
{ title: 'surgical technologist' },
{ title: 'survey researcher' },
{ title: 'surveying technician' },
{ title: 'surveyor' },
{ title: 'switch operator' },
{ title: 'switchboard operator' },
{ title: 'tailor' },
{ title: 'tamping equipment operator' },
{ title: 'tank car loader' },
{ title: 'taper' },
{ title: 'tax collector' },
{ title: 'tax examiner' },
{ title: 'tax preparer' },
{ title: 'taxi driver' },
{ title: 'teacher assistant' },
{ title: 'team assembler' },
{ title: 'technical writer' },
{ title: 'telecommunications equipment installer' },
{ title: 'telemarketer' },
{ title: 'telephone operator' },
{ title: 'television announcer' },
{ title: 'teller' },
{ title: 'terrazzo worker' },
{ title: 'tester',

  "category": "Science, Technology, Engineering, and Mathematics (STEM)",
  "subCategory": "Quality Assurance",
  interview:true


 },
{ title: 'textile bleaching operator' },
{ title: 'textile cutting machine setter' },
{ title: 'textile knitting machine setter' },
{ title: 'textile presser' },
{ title: 'textile worker' },
{ title: 'therapist' },
{ title: 'threat intel analyst' },
{ title: 'ticket agent' },
{ title: 'ticket taker' },
{ title: 'tile setter' },
{ title: 'timekeeping clerk' },
{ title: 'timing device assembler' },
{ title: 'tire builder' },
{ title: 'tire changer' },
{ title: 'tire repairer' },
{ title: 'title abstractor' },
{ title: 'title examiner' },
{ title: 'title searcher' },
{ title: 'tobacco roasting machine operator' },
{ title: 'tool filer' },
{ title: 'tool grinder' },
{ title: 'tool maker' },
{ title: 'tool sharpener' },
{ title: 'tour guide' },
{ title: 'tower equipment installer' },
{ title: 'tower operator' },
{ title: 'track switch repairer' },
{ title: 'tractor operator' },
{ title: 'tractor-trailer truck driver' },
{ title: 'trade analyst' },
{ title: 'traffic clerk' },
{ title: 'traffic technician' },
{ title: 'training and development manager' },
{ title: 'training and development specialist' },
{ title: 'transit police' },
{ title: 'translator' },
{ title: 'transportation equipment painter' },
{ title: 'transportation inspector' },
{ title: 'transportation security screener' },
{ title: 'transportation worker' },
{ title: 'trapper' },
{ title: 'travel agent' },
{ title: 'travel clerk' },
{ title: 'travel guide' },
{ title: 'tree trimmer' },
{ title: 'trimmer' },
{ title: 'truck loader' },
{ title: 'truck mechanic' },
{ title: 'tuner' },
{ title: 'turnstile installer' },
{ title: 'tutor' },
{ title: 'typist' },
{ title: 'ui designer' },
{ title: 'umpire' },
{ title: 'undertaker' },
{ title: 'unemployed' },
{ title: 'upholsterer' },
{ title: 'urban planner' },
{ title: 'usher' },
{ title: 'ux designer' },
{ title: 'valve installer' },
{ title: 'vending machine servicer' },
{ title: 'veterinarian' },
{ title: 'veterinary assistant' },
{ title: 'veterinary technician' },
{ title: 'vocational counselor' },
{ title: 'vocational education teacher' },
{ title: 'waiter' },
{ title: 'waitress' },
{ title: 'watch repairer' },
{ title: 'water treatment plant operator' },
{ title: 'weaving machine setter' },
{ title: 'web analyst' },
{ title: 'weigher' },
{ title: 'welder' },
{ title: 'wellhead pumper' },
{ title: 'wholesale buyer' },
{ title: 'wildlife biologist' },
{ title: 'window trimmer' },
{ title: 'wood patternmaker' },
{ title: 'woodworker' },
{ title: 'word processor' },
{ title: 'writer' },
{ title: 'yardmaster' },
{ title: 'zookeeper' },
{ title: 'zoologist' },
]

export default jobs;


