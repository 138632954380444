import { React, useEffect, useState ,useRef} from "react";
import { toDate,formatISO,getTime } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'

//hooks

import { getUnixTime } from "date-fns";
import Times from "./Times";

const UserAvailableTime = ({ setchosen,profile,requestInfo,setrequestInfo }) => {
  // const { zonedTimeToUtc, utcToZonedTime, format } = require('date-fns-tz')
  
  const [weekAvailabilty, setweekAvailabilty] = useState([]);

  const ChooseItem = (session) => {
    console.log("🚀 ~ ChooseItem ~ session:", session)
    const formatedDate = new Date(session.timestamp).toDateString() 
    const formatedTime = new Date(session.timestamp).toLocaleTimeString("en-US")
    const time = formatedDate.substring(10,0) +' at ' +formatedTime.replace(':00 ',' ')
  
  
    setrequestInfo({ ...requestInfo, time: session,times:[time],timeChosenYet:false });
    setchosen(true);
  };
  

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const convertTime12to24 = (time12h) => {
 
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  };
  //this function updates user available times to have them show in the order from next day instead of just from monday to sunday
  // second functionality is adding time stamp for all of those times do user have chose
  function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }
    
    const clonedObj = Array.isArray(obj) ? [] : {};
    
    for (let key in obj) {
      clonedObj[key] = deepClone(obj[key]);
    }
    
    return clonedObj;
  }
  

  const updateUserAvailabilty = () => {
    // console.log("running");
    const newarray = [];
    for (let week = 1; week <= 4; week++) {
      let endDay = week*7


      const AfterHowManyDaysAfterToday = 1
      let startDay = week*7-(7-AfterHowManyDaysAfterToday)
 
    for (let i = startDay; i <=endDay; i++) {
      const nextDay = new Date();

      nextDay.setDate(nextDay.getDate() + i);
      // console.log(nextDay)
      let dayInfo = nextDay.toString().split(" ");
      let weekDay = nextDay.toString().split(" ")[0];

      profile.availability.forEach((item) => {
        let day = deepClone(item)
        if (day.day.split(" ")[0] === weekDay) {
          day.day = [dayInfo[0], dayInfo[2]].join(" ");
          day.hour.forEach((hour) => {
            let dayArray = nextDay.toString().split(" ");
            dayArray[4] = convertTime12to24(hour.HourFormated.substring(1));
            console.log("🚀 ~ day.hour.forEach ~ dayArray:", dayArray)
            let stamp = getUnixTime(
              new Date(
                dayArray[3],
                months.indexOf(dayArray[1]),
                dayArray[2],
                dayArray[4].substring(0, 2),
                0
              )
            );
            
            
            const OriginalTimeInWrongZone = toDate(stamp*1000)
            // console.log(OriginalTimeInWrongZone)
            const formatedtime = formatISO(OriginalTimeInWrongZone).replace('T',' ').substring(0,19)
            //replace america/regina with the user/ profile time zone
            // console.log(profile)

            const originalTimeInRightZone = zonedTimeToUtc(formatedtime,  profile.timeZone)
            // console.log(originalTimeInRightZone)
            const rightTimeStamp= getTime(originalTimeInRightZone)
            hour.timestamp = rightTimeStamp;
                // console.log(originalTimeInRightZone)
                // console.log(rightTimeStamp)

                // console.log(new Date(rightTimeStamp).toLocaleTimeString("en-US").replace(':00 ',' '))
                const time = new Date(rightTimeStamp).toLocaleTimeString("en-US").replace(':00 ',' ')
                hour.time = time
                // console.log(time)
                // console.log(rightTimeStamp)
          });

          
          newarray.push({...day});
          // console.log(day)

        }
      });
    }
  }
  console.log("🚀 ~ updateUserAvailabilty ~ newarray:", newarray)

    return newarray.filter((day)=>{
      return !day.hour.find((hour)=>((hour.timestamp- Date.now()) /1000 /60/60  ) <24 ) 
    });
  };

  useEffect(() => {
    setweekAvailabilty(updateUserAvailabilty());
  }, []);

  useEffect(() => {
    if (weekAvailabilty.length < 1) {
      // console.log(updateUserAvailabilty());
    }
    // console.log('going on')
  }, [weekAvailabilty]);
  const containerRef = useRef(null);

  const scrollToRight = () => {
    const container = containerRef.current;
    container.scrollBy({ left: 100, behavior: 'smooth' });
  };
  const scrollToLeft = () => {
    const container = containerRef.current;
    container.scrollBy({ left: -100, behavior: 'smooth' });
  };

  function getMonthDayFromTimestamp(timestamp) {
    
    const date = new Date(timestamp);
    const monthNumber = date.getMonth();
    const monthName = months[monthNumber];
    const day = date.getDate();

    const formattedDate = `${monthName} ${day}${getDaySuffix(day)}`;
    
    return formattedDate;
}

function getDaySuffix(day) {
    if (day >= 11 && day <= 13) {
        return 'th';
    }
    
    switch (day % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
}
  let props = {
    containerRef, scrollToLeft, scrollToRight, weekAvailabilty, getMonthDayFromTimestamp, requestInfo, ChooseItem
  }
  return (
    
   <Times {...props}/> 
  );
};

export default UserAvailableTime;

