import React from "react";
import styles from "./content.module.css";

import UsersList from "./tinderLayout/UsersList";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import useCollection from "../../hooks/useCollection";
import { useDocumentList } from "../../hooks/useDocumentList";
import MiniSearch from "minisearch";
import getDocument from "hooks/useDoc";

import { useUpdate } from "../../hooks/useUpdate";

import FiltersList from "./components/FiltersList";
import { Typography } from "@mui/material";
import fantoms from "assets/fantoms";
import UserAlternativePage from "./userlist/Index";
import CardSkeleton from "./userlist/CardSkeleton";
import { doc } from "firebase/firestore";

import { usePostHog } from 'posthog-js/react';


export default function Body() {
  const posthog = usePostHog();

  const { user, userData, privateData } = useAuthContext();
  const { getUsers } = useDocumentList();
  const [rolesList, setRolesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [ready, setReady] = useState(false);
  const [filters, setFilters] = useState({
    subCategory: 50,
    category: 100,
    targetRole: 400,
    targetCompany: 50,
    areaOfStudy: 30,
    bootCamp: 70,
    real: 400,
  });
  const [documentsList, setDocumentList] = useState(null);
  const [filter, setFilter] = useState({
    role: null,
    Type: null,
    Company: null,
    topic: null,
    Role: null,
    day: null,
  });


  function filterUsers(filter, rankedUsers, searchFilter) {
    let delay = false;
    if (filter["Role"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) => item.targetRole === filter["Role"] ||
          item.currentPosition === filter["Role"]
      );
    }
    if (filter["Company"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) => item.targetCompany === filter["Company"] ||
          item.currentCompany === filter["Company"]
      );
    }
    if (filter["Experience level"]) {
      delay = true;
      if (filter["Experience level"] === "Entry Level") {
        rankedUsers = rankedUsers.filter((item) => item.yoe <= 2);
      } else if (filter["Experience level"] === "Mid Level") {
        rankedUsers = rankedUsers.filter(
          (item) => item.yoe >= 2 && item.yoe <= 5
        );
      } else if (filter["Experience level"] === "Senior Level") {
        rankedUsers = rankedUsers.filter((item) => item.yoe >= 5);
      }
    }
    if (filter["Availability"]) {
      delay = true;
  
      //TODO:update all availability to availability
      rankedUsers = rankedUsers.filter((item) => {
        if (Array.isArray(item.availability)) {
          return item.availability.find(
            (time) => time.hour.length > 0 && time.day === filter["Availability"]
          );
        }
        return false;
      });
    }
    if (searchFilter) {
      delay = true;
    }
    return { delay, rankedUsers };
  }
  const matchUsers = async (isClearingFilter, isClearingSearch) => {
    if (!documentsList) return;
    setReady(false);
    setUsersList([]);
    //TODO: fix the
    let searchFilter = isClearingSearch ? null : filter["search"];
    let miniSearch = new MiniSearch({
      fields: searchFilter
        ? ["targetRole", "currentPosition", "targetCompany", "currentCompany"]
        : [
            "platform",
            // "platform",
            "targetRole",
            // "targetCompany",
            // "category",
            // "experience",
            // "university",
            // "areaOfStudy",
            // "currentPosition",
            // "currentCompany",
            // "educationLevel",
            // "bootCamp",
            // "real",
            "category",
            "subCategory",
            "real",
          ], // fields to index for full-text search
      storeFields: [
        "persona",
        "id",
        "displayName",
        "real",
        "university",
        "areaOfStudy",
        "currentPosition",
        "currentCompany",
        "educationLevel",
        "aboutMe",
        "bootCamp",
        "bootCampGraduate",
        "skills",
        "topics",
        "experience",
        "targetRole",
        "targetCompany",
        "category",
        "subCategory",
        "interviews",
        "availability",
        "availability",
        "timeZone",
        "active",
        "profile",
        "camera",
        "reviews",
        "rating",
        "interviewTypes",
        "codeWars",
        "interviewTypes",
        "yoe",
        "activeDuration",
      ], // fields to return with search results
      // storeFields: Object.keys(documentsList[0])
    });

    let list = documentsList.map((item) => {
      if (!item.persona) {
        item.real = "real";
      }
      return item;
    });
    let total = [];
    // let total = [...acceptedUsers, ...ignoredUsersUpdated];
    // eslint-disable-next-line no-constant-condition
    if(posthog.getFeatureFlag('usersLayout') === 'tinderLayout'){
      const { acceptedUsers, ignoredUsers } = privateData;
      let ignoredUsersUpdated =
        ignoredUsers.filter((item) => item.dueTime >= Date.now()) || [];
  
       total = [...acceptedUsers, ...ignoredUsersUpdated];
  
       list = documentsList.map((item) => {
        if (!item.persona) {
          item.real = "real";
        }
        return item;
      });
    }
 
    let filteredArray = [...list]
      .filter((item) => !total.some((obj) => obj.id === item.id))
      .filter((item) => item.id != user.uid)
      .filter((item) => !item.error)
      .map((item) => {
        item.targetRole = item.targetRole.toLowerCase().replace(/-/g, "");
        return item;
      });

    miniSearch.addAll(filteredArray);
    let rankedUsers;
    let searchQuery = `real peerfect2023 ${userData.targetRole
      .toLowerCase()
      .replace(/-/g, "")} ${userData.targetCompany} ${userData.category} ${
      userData.subCategory
    }`;
    if (searchFilter) {
      searchQuery = searchFilter;
    }

    rankedUsers = miniSearch.search(searchQuery, {
      fuzzy: 0.2,
      boost: filters,
      prefix: true,
    });

    if (userData.profile) {
      // rankedUsers.shift();
    }
     let { delay, rankedUsers:rankedUsersByFilters } = filterUsers(filter, rankedUsers, searchFilter)
     rankedUsers = rankedUsersByFilters
     console.log("🚀 ~ matchUsers ~ rankedUsers:", rankedUsers)
    if (delay || isClearingFilter) {
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (rankedUsers.length === 0 && delay) {
      setReady("no results");
    } else if (rankedUsers.length === 0 && !delay) {
      setReady("runout");
      // update(user.uid,{ignoredUsers:[]})
    } else {
      setReady("some results");
    }
    if (false) {
    // if (import.meta.env.DEV) {
      setUsersList(
        rankedUsers
          .sort((a, b) => a.activeDuration - b.activeDuration)
          .reverse()
      );
    } else {
      setUsersList(rankedUsers);
    }
  };

  useEffect(() => {
    if (privateData && userData && documentsList) {
      matchUsers();
    }
  }, [userData, filter, privateData, documentsList]);

  useEffect(() => {
    (async () => {
      let docId = "6YgYuXNdYuagxtlCsPMH";

      let [documents, global] = await Promise.all([
        getUsers(),
        getDocument("global", docId),
      ]);

      if (global) {
        setRolesList(global.roles);
        setDocumentList([...documents]);
      } else {
        setDocumentList(documents);
      }
    })();
  }, []);


  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", margin: 3 }}
      >
        Find Mock Interview Partner
      </Typography>
      <div className={`${posthog.getFeatureFlag('usersLayout') === 'tinderLayout'&&styles.tinderLayout} ${styles.container}`}>
        <FiltersList
          setFilter={setFilter}
          filter={filter}
          matchUsers={matchUsers}
          usersList={usersList}
          rolesList={rolesList}
        />
      {posthog.getFeatureFlag('usersLayout') === 'tinderLayout'?  <div className={styles.users}>
          <UsersList documents={usersList} ready={ready} />
        </div>
        :<div className={styles.users}>
          {ready?<>
          
        <UserAlternativePage documents={usersList} ready={ready} documentsList={documentsList} />
          </>:<>
          <CardSkeleton  />
          <CardSkeleton  />
          <CardSkeleton  />
          
          </>}
        </div>}
      </div>
    </>
  );
}


