import React, { useState } from "react";
import styles from "./Card.module.css";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import jobTarget from "/target-job.png";

import CardTop from "./CardTop";
import UserModalContainer from "./modal/ModalContainer";
import Reviews from "../components/Reviews";
import useAnalytics from "src/assets/useAnalytics";
import { id } from "date-fns/locale";
import { useAuthContext } from "hooks/useAuthContext";

const Card = ({ user, index }) => {
  const { logEvent, identify } = useAnalytics();
  const {  userData:currentUserData } = useAuthContext();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [reviewModalOpen, setReviewModal] = useState(false);
  const [readMore, setReadMore] = useState(false); // State for "Read More" functionality

  // For "Read More" functionality
  const toggleReadMore = () => {
    console.log(user.aboutMe)
    setReadMore(!readMore);
  };

  let topics = [];
  topics.push(...user.interviewTypes);
  if (user.targetCompany) {
    topics.push(user.targetCompany);
  }
  if (user.topics) {
    topics.push(...user.topics);
  }

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleReviewModalOpen = () => {
    setReviewModal(true);
  };

  const handleReviewModalClose = () => {
    setReviewModal(false);
  };

  const interview = () => {
    console.log(user)
    logEvent("request-start",{
      id  : user.id,
      real: user.real,
      currentPosition:user.currentPosition,
      targetRole:user.targetRole,
      sameRole:currentUserData.targetRole===user.targetRole,
      personalTargetRole:currentUserData.targetRole
    });
    setOpenModal(true);
  };

  const MAX_LENGTH = 120; // Customize the length of the initial text shown

  return (
    <div className={styles.card}>
      <div className={styles.Container}>
        {<CardTop user={user} styles={styles} handleReviewModalOpen={handleReviewModalOpen} />}
        {user.aboutMe && (
          <>
            <Typography variant="h6" gutterBottom sx={{ fontSize: 17 }}>
              About me
            </Typography>
            <Typography style={{ textAlign: "left" }} sx={{ mb: 1 }}>
              {readMore ? user.aboutMe : `${user.aboutMe.substring(0, MAX_LENGTH)}`}
             {user.aboutMe.length>120&& <span className={styles.readMoreButton} onClick={toggleReadMore}>
                {readMore ? " Read Less" : " Read More"}
              </span>}
            </Typography>
          </>
        )}

        <Typography variant="h6" gutterBottom sx={{ fontSize: 17 }}>
          Topics Interested On:
        </Typography>
        <div className={styles.topicContainer}>
          {topics.map((topic, index) => (
            <span key={index} className={styles.topics}>
              {topic}
            </span>
          ))}
        </div>
      </div>

      <button className={styles.bookButton} onClick={interview}>
        Practice {user.targetRole} Interview
        <span className={styles.arrow}>→</span>
      </button>

      {import.meta.env.DEV && (
        <h5>
          user was created on {new Date(user.activeDuration - 604800000).toLocaleDateString()}
        </h5>
      )}

      {openModal && (
        <UserModalContainer
          item={user}
          open={openModal}
          handleClose={closeModal}
          user={user}
          setmodal={setOpenModal}
        />
      )}

      {reviewModalOpen && (
        <Reviews
          handleClose={handleReviewModalClose}
          handleClickOpen={handleReviewModalOpen}
          open={reviewModalOpen}
          user={user}
        />
      )}
    </div>
  );
};

export default Card;
