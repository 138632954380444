import { Button, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import styles from "./Skills.module.css";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import { useState } from "react";

const Skills = ({
  user,
  setUser,
  handleNext,
  handleSubmit,
  children,
  suggestedSkills,
}) => {
  const [inputValue, setInputValue] = useState("");

  const [skills, setSkills] = useState([
    "data structures and algorithms",
    "Backend",
    "frontend",
    "React",
    "Angular",
    "Microservices",
    "Azure",
    "AmazonWebServices",
    "Vue",
    "googleCloud",
    "jQuery",
    "WebSockets",
    "React Native",
    "Docker",
    "NodeJs",
    "ExpressJs",
    "Mongodb",
    "Next",
    "microsoft Office",
    "Nuxt",
    "Redux",
    "Spring",
    "Hibernate",
    "GitHub",
    "Struts",
    "Google web toolkit",
    "JavaServer Faces",
    "Grails",
    "Vaadin",
    "Blade",
    "Play",
    "Ember",
    "Meteor",
    "Polymer",
    "Aurelia",
    "Backbone",
    "C",
    "Python",
    "Java",
    "C++",
    "C#",
    "JavaScript",
    "PHP",
    "SQL",
    "Groovy",
    "Classic Visual Basic",
    "Fortran",
    "R",
    "Ruby",
    "Swift",
    "Matlab",
    "Go",
    "Perl",
    "SASS",
    "pascal",
    "Delphi",
    "Objective-C",
    "Rust",
    "Julia",
    "Ada",
    "Lisp",
    "Dart",
    "Scala",
    "COBOL",
    "ABAP",
    "Kotlin",
    "Lua",
    "TypeScript",
    "HTML",
    "CSS",
    "Visual Basic .NET",
    "Assembly language",
    "F#",
    "Scheme",
    "Prolog",
    "Bash",
  ]);

  const filter = createFilterOptions();

  let skillsFormatted = skills.map((item) => {
    return { title: item };
  });

  const handleDelete = (index) => {
    let topics = user.topics;
    topics.splice(index, 1);
    setUser({ ...user, topics });
  };


  const handleChange = (event, newValue) => {
    if (!newValue) return;
    let topics = user.topics;
    let value = newValue.inputValue || newValue.title;
    if (!topics.includes(value)) {
      topics.push(value);

      setUser({ ...user, topics });
    }


    setTimeout(()=>{
      setInputValue("");
    },1)
    //delete curent skills from skills
    let newSkills = skills.filter((item) => item !== value);
    setSkills(newSkills);
  }
  return (
    <div className={styles.container}>
      <Typography variant="h4" gutterBottom className={styles.header}>
        Choose topics and skills that you want to practice interviews for
      </Typography>

      <div className={styles.inputContainer}>
        <Autocomplete
          fullWidth
          value={inputValue}
          onChange={handleChange}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue); // Update the input value state
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.title
            );
            // console.log("🚀isExisting:", isExisting)
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          freeSolo
          handleHomeEndKeys
          id="target-role"
          options={skillsFormatted}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          variant="standard"
          renderInput={(params) => (
            <TextField
              {...params}
              value={user.role}
              placeholder={"microsoft office"}
              label=""
              variant="standard"
              helperText="Add topics"
              sx={{
                ".MuiInputBase-input": { fontSize: 24 },
                "& .MuiAutocomplete-inputRoot": {
                  minWidth: "100%",

                  borderColor: "#ccc",
                  borderRadius: "5px",
                },
              }}
            />
          )}
        />
        <div className={styles.topicsContainer}>
          {user.topics.map((item, index) => (
            <Chip
              key={index}
              label={item}
              variant="outlined"
              onDelete={() => handleDelete(index)}
              sx={{
                fontSize: "1rem",
                padding: "5px 10px",
                margin: "5px",
                backgroundColor:"white"
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
