import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Rating from "@mui/material/Rating";
import Avatar from "@mui/material/Avatar";
import Card from "./Card";
// import users from "./tempUsers";
import UserModalContainer from "./modal/ModalContainer";
import InfiniteScroll from 'react-infinite-scroller';
import { set } from "date-fns";
import ProfileCardSkeleton from "./CardSkeleton";
import useAnalytics from "src/assets/useAnalytics"

const Index = ({documents:users,ready}) => {
  const [userList, setUserList] = useState([]);
  const [initalLoad,setInitialLoad] = useState(true)
  console.log("🚀 ~ Index ~ userList:", userList)
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [loading, setLoading] = useState(false);
  const {logEvent,identify} = useAnalytics()

  const loadFunc = async () => {
    if(loading) return
    setLoading(true);
    let nextUsers = [...users].slice(userList.length, userList.length + 5);

    if(import.meta.env.DEV){
      // nextUsers = [...users]
    }
    console.log("🚀 ~ loadFunc ~ nextUsers:", nextUsers)

    setTimeout(() => {

    if (nextUsers.length === 0) {
      console.log("no more users");
      setHasMoreUsers(false);
    }  else {
      setUserList(userList.concat(nextUsers));
      if(!initalLoad){
        logEvent('load-more-users',nextUsers.length)
      }
      //make delay random between 100 and 1000
    }


    if(initalLoad){
      setInitialLoad(false)
    }
  setLoading(false);
  }, Math.floor(Math.random() * 500) + 100);
  };

  const loader = (
    <>
   {!initalLoad&& <div key="loader" className={styles.loader}>
      <div className={styles.spinner}></div>
      <span className={styles.loadingText}>Loading ...</span>
    </div>}
    </>
  );

  useEffect(() => {
    if(!hasMoreUsers ){
      loadFunc()
    }
  }, [users]);

  useEffect(() => {
  
  }, []);


  return (
    <>
    
    <InfiniteScroll
        pageStart={0}
        loadMore={loadFunc}
        hasMore={hasMoreUsers}
        loader={loader}
      >
        <div className={styles.container}>

        {userList.map((user, index) => (
          
          <Card key={index} user={user} index={index}/>
        ))}
        </div>
      </InfiniteScroll>
      {userList.length === 0 && !loading && ready === "no results" && (
      
          <div
            style={{ padding: "100px 50px", textAlign: "center" }}
            className="user-card"
          >
            <h3>no results found, try to change your search query</h3>
          </div>
       
      )}
    </>
  );
};

export default Index;
