import  { useState } from "react";
import {
  collection,
  query,
  getDocs,
  where,
  limit,
} from "firebase/firestore";
import { db } from "./../firebase/config";
import tempUsers from "./tempUsers"
export const useDocumentList =  (collectionName, Id) => {

  
  const getUsers= async(collectionName) => {
    console.log("🚀 ~ getUsers ~ collectionName:", collectionName)
  
    try{
      //TODO: remove this line and uncomment the next line
      // if(import.meta.env.MODE === 'development') return tempUsers
      
      // let  ref = query(collection(db, "users"),where('activeDuration','>=',1000),limit(20))
      let  ref = query(collection(db, "users"),where('activeDuration','>=',1000),where('onBoarding','==',true))
      // let  ref = query(collection(db, "users"))
      
      const documentSnapshots = await getDocs(ref);
      const docs = documentSnapshots.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      console.log('request for docs',docs)
      // setDocuments(docs)
      return docs
    }catch(err){
      console.log(err,'error getting docs ***********')
    }
    
  }
  const getDocuments= async(collectionName,queries)=>{

    
    try{
      let queriesList = [];
      for (let q of queries){
        queriesList.push(where(q[0],q[1],q[2]))
      }
      let ref = query(collection(db, collectionName), ...queriesList);
      // let  ref = query(collection(db, "users"))
      const documentSnapshots = await getDocs(ref);
      const docs = documentSnapshots.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      // setDocuments(docs)
      return docs
    }catch(err){
      console.log(err)
    }
    
  }


return { getUsers ,getDocuments};

};
