import React, { useState } from 'react';
import { useFireStore } from 'hooks/useFireStore';
import { useCostumUpdate } from "hooks/useCostumUpdate";
import { useAuthContext } from "hooks/useAuthContext";
import InterviewList from './InterviewList';
import { useUpdate } from 'hooks/useUpdate';
import { useNavigate } from "react-router-dom";
import CancelInterviewConfirmation from './CancelInterviewConfirmation';
import useAnalytics from "src/assets/useAnalytics"

export default function Interviews({documents, privateData}) {
    const { deleteDocument, response } = useFireStore('interviews');
    const { addNotification } = useCostumUpdate("notification");
    const { authIsReady, user } = useAuthContext();
    const { update, setloading } = useUpdate("interviews");
    const navigate = useNavigate();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [interviewToCancel, setInterviewToCancel] = useState(null);
    const [isCancelling, setIsCancelling] = useState(false);
    const {logEvent,identify} = useAnalytics()

    const openCancelConfirmation = (index) => {
        setInterviewToCancel(index);
        setConfirmationOpen(true);
    };

    const closeCancelConfirmation = () => {
        if (!isCancelling) {
            setConfirmationOpen(false);
            setInterviewToCancel(null);
        }
    };

    const confirmCancelInterview = async () => {
        if (interviewToCancel !== null) {
            setIsCancelling(true);
            try {
                await cancelInterview(interviewToCancel);
            } catch (error) {
                console.error("Error cancelling interview:", error);
                // Handle error (e.g., show an error message to the user)
            } finally {
                setIsCancelling(false);
                closeCancelConfirmation();
            }
        }
    };

    const cancelInterview = async (index) => {
        const otherUser = documents[index].users.find((e) => user.uid !== e.id);
        const cancellingUser = user.uid;
        const notif = {
            chosenTime: documents[index].time,
            role: documents[index].role,
            interviewType: documents[index].interviewType,
            notificationTimeStamp: Date.now(),
            notificationType: "Cancel",
            name: otherUser.name
        };
        
        await update(documents[index].id, {cancel: true, cancellingUser, cancelledUser: otherUser.id});
        await addNotification(otherUser.id, notif, 'notifications');
        await deleteDocument(documents[index].id);
        logEvent("interview-cancel", {
            chosenTime: documents[index].time,
            role: documents[index].role,
            interviewType: documents[index].interviewType,
        });
    };

    const startInterview = async (row) => {
        logEvent("interview-start-button",{    
            // chosenTime: documents[index].time,
            // role: documents[index].role,
            // interviewType: documents[index].interviewType
        }
 );
        navigate(`/meet/${row.roomId}`);
    };

    return (
        <>
            <div id="interviewContainer">
                <InterviewList
                    privateData={privateData}
                    documents={documents}
                    cancelInterview={openCancelConfirmation}
                    user={user}
                    startInterview={startInterview}
                />
            </div>
            <CancelInterviewConfirmation
                isOpen={confirmationOpen}
                onClose={closeCancelConfirmation}
                onConfirm={confirmCancelInterview}
                isLoading={isCancelling}
                otherUser={interviewToCancel !== null ? documents[interviewToCancel]?.users.find((e) => user.uid !== e.id) : null}
            />
        </>
    );
}