import { initializeApp } from "firebase/app";

import { getFirestore , connectFirestoreEmulator } from "firebase/firestore";

import { getAuth,connectAuthEmulator } from "firebase/auth";


import { getStorage } from "firebase/storage";

import { getApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";


import { getPerformance } from "firebase/performance";

import { clarity } from 'react-microsoft-clarity';
import { getAnalytics,logEvent  } from "firebase/analytics";
import Plausible from 'plausible-tracker'

const firebaseConfig = require('./firebaseconfig.js')
// import  firebaseConfig from './firebaseconfig.js'
// import { initializeFirestore } from 'firebase/firestore'


const app = initializeApp(firebaseConfig);

const db = getFirestore()

const auth = getAuth()

const projectStorage = getStorage()


const realtimedb = getDatabase();

const analytics = getAnalytics(app);




// console.log("🚀 ~ import.meta.DEV:", import.meta.env.DEV)
//import.meta.env.DEV
if (import.meta.env.DEV ) {
    // // Point to the RTDB emulator running on localhost.
    connectDatabaseEmulator(realtimedb, "localhost", 9000);
    
    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, 'localhost', 8080);
    
    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "localhost", 5001);
  } else{
     const perf = getPerformance(app);
      logEvent(analytics, 'screen_view');
      clarity.init("iiz1nhaxrn");
      const plausibled = Plausible({
        domain: 'peerfect.net',
        apiHost: 'https://plausible.peerfect.net/',
        trackLocalhost: true,
        // hashMode: true
      })
    
    plausibled.enableAutoPageviews()



    
  }

  
  
export { db, auth,projectStorage }