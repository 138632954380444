import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { AuthContextProvider } from "./context/AuthContext";
import * as Sentry from "@sentry/react";
// import { ErrorBoundary } from 'react-error-boundary';
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';


if (import.meta.env.PROD) {
  console.log = () => {}
  // console.error = () => {}
  // console.debug = () => {}

    
Sentry.init({
  dsn: "https://93a1a46c8f5a1fb2631962e21749cb36@o4507875581100032.ingest.us.sentry.io/4507875584835584",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


}


posthog.init('phc_ulXz34IngTv6zYpVEM9YbmtE6iRVo2eXTqUPFhssNco',
  {
      api_host: 'https://orange.peerfect.net/',
      person_profiles: 'always',// or 'always' to create profiles for anonymous users as well
      disable_session_recording: import.meta.env.DEV,
  }
)

if(import.meta.env.DEV){
  // posthog.opt_out_capturing();

}
ReactDOM.createRoot(document.getElementById('root')).render(
        <AuthContextProvider>

{/* <ErrorBoundary fallback={<p>Something went wrong. Try again later.</p>}> */}
<PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
    {/* </ErrorBoundary> */}
        </AuthContextProvider>
  ,
)
