import React from "react";
import "./notification.css";
import { fromUnixTime,format  } from "date-fns";
import Typography from "@mui/material/Typography";
import EventAvailableTwoToneIcon from "@mui/icons-material/EventAvailableTwoTone";
import ScheduleSendTwoToneIcon from "@mui/icons-material/ScheduleSendTwoTone";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import {  useNavigate } from "react-router-dom";
import ReviewsIcon from '@mui/icons-material/Reviews';

const humanizeDuration = require("humanize-duration");

export default function NotificationCard({ item, index, handleClose,notificationNumber }) {
  console.log("🚀 ~ file: NotificationCard.js:12 ~ NotificationCard ~ item:", item)
  const navigate = useNavigate();

  const timestamp = item.notificationTimeStamp;
  const now = Math.floor(Date.now());
  const diffrence = humanizeDuration(now-timestamp,{ largest: 1,round: true  })


let date
if(item.chosenTime){
  date =format(fromUnixTime(item.chosenTime?.timestamp), "EEE MMM d 'at' HH:mm")
}
  return (
    <div className="notification seen" >
      

    
      {item.userSendingRequest &&  (
        <>
      {item.notificationType === "request" && (
        <div className="notification-card" onClick={()=>navigate('/interviews?tab=1')}>
          <div className="icon">
            <ScheduleSendTwoToneIcon sx={{ fontSize: 55, color: "#01579b" }} />
          </div>
          <h4 style={{fontWeight: 'normal'}}>
      <span style={{fontWeight: 'bold'}}>{item.userSendingRequest.charAt(0).toUpperCase()+item.userSendingRequest.slice(1)} </span> 
        has sent an request for <span style={{fontWeight: 'bold'}}>{item.role}</span> interview at  
        <span style={{fontWeight: 'bold'}}> {date.replace(" at",",")}</span>
        
      <br/>
      <Typography variant="caption" >
      {diffrence} ago 
</Typography>
      </h4>
        </div>
      )}
      </>

)}
      {item.notificationType === "Accept" && (
        <div className="notification-card" onClick={()=>navigate('/interviews')}>
          <div className="icon">
            <EventAvailableTwoToneIcon sx={{ fontSize: 55, color: "#4caf50" }} />
          </div>
          <h4 style={{fontWeight: 'normal'}}>
      <span style={{fontWeight: 'bold'}}>{item.user.charAt(0).toUpperCase()+item.user.slice(1)} </span> 
        has accepted your <span style={{fontWeight: 'bold'}}>{item.interviewType}</span>  interview request, and you have interview on 
        <span style={{fontWeight: 'bold'}}> {date}</span>
        <br/>
      <Typography variant="caption" >
      {diffrence} ago 
</Typography>
      </h4>
        </div>
      )}


      {item.notificationType === "Cancel" && (
        <div className="notification-card" onClick={()=>navigate('/interviews')}>
          <div className="icon">
            <EventBusyIcon sx={{ fontSize: 55, color: "#d50000" }} />
          </div>
          <h4 style={{fontWeight: 'normal'}}>
      {/* <span style={{fontWeight: 'bold'}}>{item.user.charAt(0).toUpperCase()+item.user.slice(1)} </span>  */}
        Your <span style={{fontWeight: 'bold'}}>{item.interviewType}</span>  interview on  
        <span style={{fontWeight: 'bold'}}> {date} </span>
        has heen cancelled
        <br/>
      <Typography variant="caption" >
      {diffrence} ago 
</Typography>
      </h4>
        </div>
      )}

        

    </div>
  );
}
