import React from "react";
import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//steper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

//file imports

//firebase imports
import { useFireStore } from "./../../hooks/useFireStore";
import { useAuthContext } from "./../../hooks/useAuthContext";
import { useCostumUpdate } from "./../../hooks/useCostumUpdate";
import { Link, useNavigate } from "react-router-dom";

//loading button for submit
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import UserInterviewDetails from "./UserInterviewDetails";

import InterviewTypes from "./InterviewTypes";
import InterviewRole from "./InterviewRole";
import useAnalytics from "src/assets/useAnalytics"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const InviteFriendModal = ({
  open,
  handleClose,
  loading,
  success,
  
}) => {
  //data functions
  const navigate = useNavigate();
  const [chosen, setchosen] = useState(false);

  const { addDocument, repsonse } = useFireStore("requests");
  const { user,dispatch,userData } = useAuthContext();
  const { addNotification } = useCostumUpdate("notification");
  let item = userData
  let profile = item
  console.log("🚀 ~ profile:", profile)

  const {logEvent,identify} = useAnalytics()

  const [requestInfo, setrequestInfo] = useState({
    time: { HourFormated: " initial value", timestamp: 2202156 },
    yoe: profile.yoe , 
    interviewType: profile.interviewTypes[0] || "general interview",
    interviewDetails: '',
    role: profile.targetRole,
    userSendingRequestId: user ? user.uid : null,
    userSendingRequestTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userSendingRequestName: user ? user.displayName : null,
    category:profile.category,
    subCategory:profile.subCategory,
    timeChosenYet:true
  });

  const submitData = async() => {

    const requestObject = requestInfo

    if(!requestObject.interviewType){
      requestObject.interviewType = "general interview"
    }

    
    requestObject.deleteTime= requestObject.time.timestamp-3600000
    

    addDocument(requestObject);
    // return
    logEvent('Interview Request',{
      'role':requestInfo.role,
      'interview type': requestInfo.interviewType,
      'category': profile.category,
      'subCategory': profile.subCategory,
      'yoe':requestInfo.yoe,
    });
    

   
   
    handleClose();
      
    // skips=0
  };

  useEffect(() => {
    console.log(requestInfo)
  }, [requestInfo])
  
  //modal stuff
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    console.log(requestInfo)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activeStep === 3 || modalSteps.length ===1) {
      submitData()
    } else {
      handleNext();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const [modalSteps, setModalSteps] = useState(  [  "Interview Details","Invite your friend to practice", 
]);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const props = {
    userData,
    user,
    setchosen,
    profile,
    requestInfo,
    setrequestInfo,
  };

  return (
    <div>
      <BootstrapDialog
        maxWidth={activeStep === 2 ? "md" : "sm"}
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            <Stepper activeStep={activeStep} alternativeLabel>
              {modalSteps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                //
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </BootstrapDialogTitle>

          {/* modal main stuff */}
         
          <DialogContent dividers sx={{m:0,p:0,bgcolor:activeStep === 3?'#f5f5f5':'white'}}>
        

            {activeStep === 0 && <InterviewRole {...props}/>}
            {activeStep === 1 && <InterviewTypes {...props}/>}
            {activeStep === 2 && <UserInterviewDetails {...props}/>}
    
          </DialogContent>

          {/* modal footer */}
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>

            {activeStep === modalSteps.length - 1 ? (
              //  <Button onClick={handleSubmit}>  Submit </Button>
              <Box sx={{ m: 1, position: "relative" }}>
                {!success && (
                  <Button
                    variant="contained"
                    sx={buttonSx}
                    type="submit"
                    disabled={loading || requestInfo.timeChosenYet}
                  >
                    Submit
                  </Button>
                )}
                {success && (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "#4caf50" }}
                    disabled={loading}
                    type="submit"
                  >
                    Success
                  </Button>
                )}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            ) : (
              <Button type="submit" disabled={(requestInfo.role ==="" &&activeStep===0 )||(requestInfo.interviewType ==="" &&activeStep===1 )}> Next</Button>
            )}
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default InviteFriendModal;
